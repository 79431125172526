import { css } from 'lit';
import { Action } from 'redux';
import { isEqual } from 'lodash-es';
import { createSelector } from 'reselect';
import { Banner, BannerLocation, NotificationErrorType } from '../../typings/shared-types';
import { RootState, ThunkActionRoot } from './redux-store';

//	STATE

export type NotificationBanners = { [key in NotificationErrorType]: Banner[] };
export interface UiState {
	isBodyNoScroll: boolean;
	isLoadingRoute: boolean;
	isMobileSearchMenuVisible: boolean;
	banners?: NotificationBanners;
}

export const getEmptyNotificationBanners = (): NotificationBanners => ({
	[NotificationErrorType.ERROR]: [],
	[NotificationErrorType.SUCCESS]: [],
	[NotificationErrorType.WARNING]: [],
});

export const UI_STATE_INITIAL: UiState = {
	isBodyNoScroll: false,
	isLoadingRoute: false,
	isMobileSearchMenuVisible: false,
	banners: getEmptyNotificationBanners(),
};

//	ACTION TYPING
type NotificationBannerMessaging = {
	title: string;
	messages?: string | (string | undefined)[];
};

export enum UiActionType {
	SET_BODY_NO_SCROLL = 'SET_BODY_NO_SCROLL',
	SET_LOADING_ROUTE = 'SET_LOADING_ROUTE',
	SHOW_NOTIFICATION_BANNER = 'SHOW_NOTIFICATION_BANNER',
	HIDE_NOTIFICATION_BANNER = 'HIDE_NOTIFICATION_BANNER',
	CLEAR_NOTIFICATION_BANNERS = 'CLEAR_NOTIFICATION_BANNERS',
	IS_MOBILE_SEARCH_MENU_VISIBLE = 'IS_MOBILE_SEARCH_MENU_VISIBLE',
}

interface SetBodyNoScroll extends Action<typeof UiActionType.SET_BODY_NO_SCROLL> {
	isBodyNoScroll: boolean;
}

interface SetLoadingRoute extends Action<typeof UiActionType.SET_LOADING_ROUTE> {
	isLoadingRoute: boolean;
}

interface ShowNotificationBanner extends Action<typeof UiActionType.SHOW_NOTIFICATION_BANNER> {
	notificationType: NotificationErrorType;
	messaging: NotificationBannerMessaging;
	timeout?: number;
	location?: BannerLocation;
	id?: number;
}
interface HideNotificationBanner extends Action<typeof UiActionType.HIDE_NOTIFICATION_BANNER> {
	id: number;
}

interface ClearNotificationBanners extends Action<typeof UiActionType.CLEAR_NOTIFICATION_BANNERS> {
	location: BannerLocation;
}

interface IsMobileSearchMenuVisible
	extends Action<typeof UiActionType.IS_MOBILE_SEARCH_MENU_VISIBLE> {
	isVisible: boolean;
}

export type UiAction =
	| SetBodyNoScroll
	| SetLoadingRoute
	| ShowNotificationBanner
	| HideNotificationBanner
	| ClearNotificationBanners
	| IsMobileSearchMenuVisible;
//	ACTIONS

/**
 * Used to prevent the body from scrolling when there is a modal open
 *
 * @param {boolean} isBodyNoScroll - whether or not to prevent scrolling
 * @returns {Function} redux action
 */
export function setBodyNoScroll(isBodyNoScroll: boolean): SetBodyNoScroll {
	return {
		type: UiActionType.SET_BODY_NO_SCROLL,
		isBodyNoScroll,
	};
}

// eslint-disable-next-line jsdoc/require-jsdoc
export function setLoadingRoute(isLoadingRoute: boolean): SetLoadingRoute {
	return {
		type: UiActionType.SET_LOADING_ROUTE,
		isLoadingRoute,
	};
}

export const showMainBanner = (
	notificationType: NotificationErrorType,
	messaging: NotificationBannerMessaging,
	_timeout?: number,
): ShowNotificationBanner => {
	let timeout = _timeout;

	if (timeout === undefined && notificationType === NotificationErrorType.SUCCESS) {
		timeout = 5000;
	} else if (timeout === undefined) {
		timeout = 10000;
	}
	return {
		type: UiActionType.SHOW_NOTIFICATION_BANNER,
		location: BannerLocation.MAIN,
		notificationType,
		messaging,
		timeout,
	};
};

export const showModalBanner =
	(
		notificationType: NotificationErrorType,
		messaging: NotificationBannerMessaging,
		_timeout?: number,
	): ThunkActionRoot<void> =>
	(dispatch): void => {
		const id = Date.now();
		let timeout = _timeout;
		if (timeout === undefined && notificationType === NotificationErrorType.SUCCESS) {
			timeout = 5000;
		} else if (timeout === undefined) {
			timeout = 10000;
		}
		dispatch({
			type: UiActionType.SHOW_NOTIFICATION_BANNER,
			id,
			location: BannerLocation.MODAL,
			notificationType,
			messaging,
			timeout,
		});
		setTimeout(() => {
			dispatch({ type: UiActionType.HIDE_NOTIFICATION_BANNER, id });
		}, timeout);
	};

export const hideNotificationBanner = (id: number): HideNotificationBanner => ({
	type: UiActionType.HIDE_NOTIFICATION_BANNER,
	id,
});

export const clearNotificationBanners = (location: BannerLocation): ClearNotificationBanners => ({
	type: UiActionType.CLEAR_NOTIFICATION_BANNERS,
	location,
});

export const isMobileSearchMenuVisible =
	(isVisible: boolean): ThunkActionRoot<void> =>
	(dispatch): void => {
		dispatch({ type: UiActionType.IS_MOBILE_SEARCH_MENU_VISIBLE, isVisible });
		dispatch(setBodyNoScroll(isVisible));
	};

//	REDUCER

export const UiReducer = (
	state: UiState = UI_STATE_INITIAL,
	action: UiAction | undefined = undefined,
): UiState => {
	if (action === undefined) {
		return state;
	}
	switch (action.type) {
		case UiActionType.SET_BODY_NO_SCROLL:
			return {
				...state,
				isBodyNoScroll: action.isBodyNoScroll,
			};
		case UiActionType.SET_LOADING_ROUTE:
			return {
				...state,
				isLoadingRoute: action.isLoadingRoute,
			};
		case UiActionType.SHOW_NOTIFICATION_BANNER:
			switch (action.notificationType) {
				case NotificationErrorType.ERROR:
				case NotificationErrorType.SUCCESS:
				case NotificationErrorType.WARNING:
					return {
						...state,
						banners: {
							[NotificationErrorType.ERROR]: [
								...(state.banners?.[NotificationErrorType.ERROR] ?? []),
							],

							[NotificationErrorType.SUCCESS]: [
								...(state.banners?.[NotificationErrorType.SUCCESS] ?? []),
							],

							[NotificationErrorType.WARNING]: [
								...(state.banners?.[NotificationErrorType.WARNING] ?? []),
							],
							...state.banners,
							[action.notificationType]: [
								...(state.banners?.[action.notificationType] ?? []),
								{
									id: action.id ?? Date.now(),
									type: action.notificationType,
									messaging: action.messaging,
									timeout: action.timeout,
									location: action.location,
								},
							],
						},
					};
				default:
					return state;
			}
		case UiActionType.HIDE_NOTIFICATION_BANNER:
			return {
				...state,
				banners: {
					SUCCESS: state.banners?.SUCCESS.filter((banner) => banner.id !== action.id) ?? [],
					ERROR: state.banners?.ERROR.filter((banner) => banner.id !== action.id) ?? [],
					WARNING: state.banners?.WARNING.filter((banner) => banner.id !== action.id) ?? [],
				},
			};
		case UiActionType.CLEAR_NOTIFICATION_BANNERS:
			return {
				...state,
				banners: {
					SUCCESS:
						state.banners?.SUCCESS.filter((banner) => banner.location !== action.location) ?? [],
					ERROR: state.banners?.ERROR.filter((banner) => banner.location !== action.location) ?? [],
					WARNING:
						state.banners?.WARNING.filter((banner) => banner.location !== action.location) ?? [],
				},
			};
		case UiActionType.IS_MOBILE_SEARCH_MENU_VISIBLE:
			return {
				...state,
				isMobileSearchMenuVisible: action.isVisible,
			};
		default:
			return state;
	}
};

// SELECTORS

export const selectBannersActive = (state: RootState, location: BannerLocation): boolean => {
	if (!state.ui.banners) {
		return false;
	}
	return Object.values(state.ui?.banners).some(
		(x) => x.some((banner) => banner.location === location) && x.length > 0,
	);
};

export const getBanners = createSelector(
	[
		(state: RootState): UiState['banners'] | undefined => state.ui.banners,
		(_state: RootState, location?: BannerLocation) => location,
	],
	(uiBanners, location): UiState['banners'] => {
		if (!uiBanners) {
			return getEmptyNotificationBanners();
		}
		const result: UiState['banners'] = getEmptyNotificationBanners();
		Object.entries(uiBanners).forEach(([type, banners]) => {
			const bannerType = type as NotificationErrorType;
			result[bannerType] = [];
			banners.forEach((banner) => {
				if (
					banner.location === location &&
					result[bannerType]?.find((x) => isEqual(x.messaging, banner.messaging)) === undefined
				) {
					result[bannerType]?.push(banner);
				}
			});
		});
		return result;
	},
);
