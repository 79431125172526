import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { normalize } from 'normalizr';
import { Action } from 'redux';
import {
	AuthorityDto,
	EditRoleDto,
	EditUserDto,
	PermissionGroupType,
	RoleWithUsersDto,
} from '../../../typings/api';
import { ThunkActionRoot } from '../redux-store';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { ConfigUserManagementApi } from '../../config/ConfigLogin';
import { APIConfig } from '../../config/ConfigCARSx';
import { navigate } from '../redux-routing';
import { NotificationErrorType } from '../../../typings/shared-types';
import { showMainBanner, showModalBanner } from '../redux-ui';
import { rolesSchema } from '../../schemas';
import { UserActionType, selectLandingPageForUser, setJwt } from '../redux-user';

export enum UserManagementActionType {
	UPDATE_USER = 'UPDATE_USER',
	UPDATE_ROLE = 'UPDATE_ROLE',

	DELETE_USER = 'DELETE_USER',
	DELETE_ROLE = 'DELETE_ROLE',

	GET_ROLES_WITH_USERS = 'GET_ROLES_WITH_USERS',
	GET_PERMMISSION_TYPES = 'GET_PERMMISSION_TYPES',

	API_ERROR_PAGE = 'API_ERROR_PAGE',

	USER_MANAGEMENT_LOADING = 'USER_MANAGEMENT_LOADING',

	EXPAND_COLLAPSIBLE_ROLES = 'EXPAND_COLLAPSABLE_ROLES',
	EXPAND_COLLAPSIBLE_ROLE = 'EXPAND_COLLAPSABLE_ROLE',
}
export type RolesWithUsersEntities = {
	roles: Record<number, RoleWithUsersDto>;
	users: Record<number, EditUserDto>;
};
export interface GetRolesWithUsers
	extends Action<typeof UserManagementActionType.GET_ROLES_WITH_USERS> {
	roleIds: number[];
	entities: RolesWithUsersEntities;
}
export interface GetPermissionTypes
	extends Action<typeof UserManagementActionType.GET_PERMMISSION_TYPES> {
	permissionGroups: PermissionGroupType[];
}
export interface UpdateUser extends Action<typeof UserManagementActionType.UPDATE_USER> {
	user: EditUserDto;
}
export interface UpdateRole extends Action<typeof UserManagementActionType.UPDATE_ROLE> {
	roleId: number;
	entities: RolesWithUsersEntities;
}

export interface DeleteRole extends Action<typeof UserManagementActionType.DELETE_ROLE> {
	roleId: number;
}
export interface DeleteUser extends Action<typeof UserManagementActionType.DELETE_USER> {
	userId: number;
}
export type ApiErrorPage = Action<typeof UserManagementActionType.API_ERROR_PAGE>;

export interface ExpandCollapsibleRoles
	extends Action<typeof UserManagementActionType.EXPAND_COLLAPSIBLE_ROLES> {
	isExpanded: boolean;
}

export interface ExpandCollapsibleRole
	extends Action<typeof UserManagementActionType.EXPAND_COLLAPSIBLE_ROLE> {
	roleId: number;
	isExpanded: boolean;
}

export type UserManagementAction =
	| ApiErrorPage
	| GetRolesWithUsers
	| GetPermissionTypes
	| UpdateUser
	| UpdateRole
	| DeleteRole
	| DeleteUser
	| ExpandCollapsibleRoles
	| ExpandCollapsibleRole;

const getRolesWithUsers =
	(): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const apiRequestReturn = await APIRequest(
			new Request(
				new URL(ConfigUserManagementApi.getRolesWithUsers(), APIConfig.endpointURLBase).href,
				{
					method: HTTPMethod.GET,
					headers: new Headers({ ...getAPIHeaders() }),
				},
			),
		);

		if (apiRequestReturn.apiError) {
			console.error(`error getting role list:`, apiRequestReturn.apiError);
			dispatch({ type: UserManagementActionType.API_ERROR_PAGE });
			return apiRequestReturn;
		}

		try {
			const resp = (await apiRequestReturn.response?.json()) as RoleWithUsersDto[];
			const normalized = normalize<RoleWithUsersDto, RolesWithUsersEntities, number[]>(resp, [
				rolesSchema,
			]);
			dispatch({
				type: UserManagementActionType.GET_ROLES_WITH_USERS,
				roleIds: normalized.result,
				entities: normalized.entities,
			});
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			console.error(`error parsing role list:`, error);
			dispatch({ type: UserManagementActionType.API_ERROR_PAGE });
		}
		return apiRequestReturn;
	};

let pollingRolesTimeout: ReturnType<typeof setInterval>;

export const pollRolesWithUsers =
	(pollImmediately = false): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		if (pollImmediately) await dispatch(getRolesWithUsers());

		pollingRolesTimeout = setInterval(() => {
			void dispatch(getRolesWithUsers());
		}, ConfigUserManagementApi.rolePollingRate);
	};

export const stopPollRolesWithUsers = () => {
	clearInterval(pollingRolesTimeout);
};

export const getPermissionTypes =
	(): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const apiRequestReturn = await APIRequest(
			new Request(
				new URL(ConfigUserManagementApi.getPermissionTyes(), APIConfig.endpointURLBase).href,
				{
					method: HTTPMethod.GET,
					headers: new Headers({ ...getAPIHeaders() }),
				},
			),
		);

		if (apiRequestReturn.apiError) {
			console.error(`error getting permission list:`, apiRequestReturn.apiError);
			dispatch({ type: UserManagementActionType.API_ERROR_PAGE });
			return apiRequestReturn;
		}

		try {
			const resp = (await apiRequestReturn.response?.json()) as PermissionGroupType[];
			dispatch({
				type: UserManagementActionType.GET_PERMMISSION_TYPES,
				permissionGroups: resp,
			});
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			console.error(`error parsing permission list:`, error);
			dispatch({ type: UserManagementActionType.API_ERROR_PAGE });
		}
		return apiRequestReturn;
	};

export const saveRole =
	(role: EditRoleDto, roleId?: number | null): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		let request: Request;
		if (roleId !== undefined && roleId !== null) {
			request = new Request(
				new URL(ConfigUserManagementApi.editRole(roleId), APIConfig.endpointURLBase).href,
				{
					method: HTTPMethod.PUT,
					headers: new Headers({ ...getAPIHeaders() }),
					body: JSON.stringify(role),
				},
			);
		} else {
			request = new Request(
				new URL(ConfigUserManagementApi.createRole(), APIConfig.endpointURLBase).href,
				{
					method: HTTPMethod.POST,
					headers: new Headers({ ...getAPIHeaders() }),
					body: JSON.stringify(role),
				},
			);
		}
		const apiRequestReturn = await APIRequest(request);

		try {
			if (apiRequestReturn.apiError) {
				console.error(apiRequestReturn.apiError);
				dispatch(
					showModalBanner(NotificationErrorType.ERROR, {
						title: `Application Error. Group not saved.`,
					}),
				);
				return apiRequestReturn;
			}
			const resp = (await apiRequestReturn.response?.json()) as RoleWithUsersDto;
			const normalized = normalize<RoleWithUsersDto, RolesWithUsersEntities, number>(
				resp,
				rolesSchema,
			);
			dispatch({
				type: UserManagementActionType.UPDATE_ROLE,
				roleId: normalized.result,
				entities: normalized.entities,
			});
			dispatch(showMainBanner(NotificationErrorType.SUCCESS, { title: 'Group saved' }));
		} catch (error) {
			console.error(error);
			dispatch(
				showModalBanner(NotificationErrorType.ERROR, {
					title: `Application Error. Group not saved.`,
				}),
			);
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
		}
		return apiRequestReturn;
	};

export const saveUser =
	(user: EditUserDto, userId?: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		let request: Request;
		if (userId !== undefined) {
			request = new Request(
				new URL(ConfigUserManagementApi.editUser(userId), APIConfig.endpointURLBase).href,
				{
					method: HTTPMethod.PUT,
					headers: new Headers({ ...getAPIHeaders() }),
					body: JSON.stringify(user),
				},
			);
		} else {
			request = new Request(
				new URL(ConfigUserManagementApi.createUser(), APIConfig.endpointURLBase).href,
				{
					method: HTTPMethod.POST,
					headers: new Headers({ ...getAPIHeaders() }),
					body: JSON.stringify(user),
				},
			);
		}
		const apiRequestReturn = await APIRequest(request);

		try {
			if (apiRequestReturn.apiError) {
				dispatch(
					showModalBanner(NotificationErrorType.ERROR, {
						title: `Application Error. User not saved.`,
					}),
				);
				return apiRequestReturn;
			}
			const resp = (await apiRequestReturn.response?.json()) as EditUserDto;
			dispatch({ type: UserManagementActionType.UPDATE_USER, user: resp });
			dispatch(showMainBanner(NotificationErrorType.SUCCESS, { title: 'User saved' }));
		} catch (error) {
			console.error(error);
			dispatch(
				showModalBanner(NotificationErrorType.ERROR, {
					title: `Application Error. User not saved.`,
				}),
			);
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
		}

		return apiRequestReturn;
	};

export const deleteRole =
	(roleId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const request = new Request(
			new URL(ConfigUserManagementApi.deleteRole(roleId), APIConfig.endpointURLBase).href,
			{
				method: HTTPMethod.DELETE,
				headers: new Headers({ ...getAPIHeaders() }),
			},
		);
		const apiRequestReturn = await APIRequest(request);

		try {
			if (apiRequestReturn.apiError) {
				dispatch(
					showModalBanner(NotificationErrorType.ERROR, {
						title: `Application Error. Group not deleted`,
					}),
				);
				return apiRequestReturn;
			}
			dispatch({ type: UserManagementActionType.DELETE_ROLE, roleId });
			dispatch(showMainBanner(NotificationErrorType.SUCCESS, { title: 'Group deleted' }));
		} catch (error) {
			console.error(error);
			dispatch(
				showModalBanner(NotificationErrorType.ERROR, {
					title: `Application Error. Group not deleted`,
				}),
			);
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
		}
		return apiRequestReturn;
	};

export const deleteUser =
	(userId: number, isModal = false): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const request = new Request(
			new URL(ConfigUserManagementApi.deleteUser(userId), APIConfig.endpointURLBase).href,
			{
				method: HTTPMethod.DELETE,
				headers: new Headers({ ...getAPIHeaders() }),
			},
		);
		const apiRequestReturn = await APIRequest(request);

		if (apiRequestReturn.apiError) {
			dispatch(
				(isModal ? showModalBanner : showMainBanner)(NotificationErrorType.ERROR, {
					title: `Application Error. User not deleted`,
				}),
			);
			return apiRequestReturn;
		}
		dispatch({ type: UserManagementActionType.DELETE_USER, userId });
		dispatch(showMainBanner(NotificationErrorType.SUCCESS, { title: 'User deleted' }));
		return apiRequestReturn;
	};

export const expandCollapseCollapsableRoles = (isExpanded: boolean): ExpandCollapsibleRoles => ({
	type: UserManagementActionType.EXPAND_COLLAPSIBLE_ROLES,
	isExpanded,
});

export const expandCollapseCollapsableRole = (
	roleId: number,
	isExpanded: boolean,
): ExpandCollapsibleRole => ({
	type: UserManagementActionType.EXPAND_COLLAPSIBLE_ROLE,
	roleId,
	isExpanded,
});

export const changeUsersPassword =
	(userId: number, password: string): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch) => {
		const request = new Request(
			new URL(ConfigUserManagementApi.changeUserPassword(userId), APIConfig.endpointURLBase).href,
			{
				method: HTTPMethod.PUT,
				headers: new Headers({ ...getAPIHeaders() }),
				body: JSON.stringify({ password }),
			},
		);
		const apiRequestReturn = await APIRequest(request);

		if (apiRequestReturn.apiError) {
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: `Application Error. Password not reset for user.`,
				}),
			);

			return apiRequestReturn;
		}

		dispatch(showMainBanner(NotificationErrorType.SUCCESS, { title: `Password reset for user.` }));
		return apiRequestReturn;
	};

export const sendPasswordResetEmail =
	(username: string): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch) => {
		const request = new Request(
			new URL(
				ConfigUserManagementApi.sendPasswordResetEmail(username),
				APIConfig.endpointURLBase,
			).href,
			{
				method: HTTPMethod.GET,
				headers: new Headers({ ...getAPIHeaders() }),
			},
		);
		const apiRequestReturn = await APIRequest(request);

		if (apiRequestReturn.apiError) {
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: `Application Error. Password reset email not sent.`,
				}),
			);

			return apiRequestReturn;
		}

		dispatch(
			showMainBanner(NotificationErrorType.SUCCESS, {
				title: `A password reset email has been sent to the requested email (if an account exists). Please check your inbox.`,
			}),
		);
		return apiRequestReturn;
	};

let passwordResetToken: string | null = null;

export const checkPasswordResetToken =
	(token: string): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch) => {
		const request = new Request(
			new URL(
				ConfigUserManagementApi.checkPasswordResetToken(token),
				APIConfig.endpointURLBase,
			).href,
			{
				method: HTTPMethod.GET,
				headers: new Headers({ ...getAPIHeaders() }),
			},
		);
		const apiRequestReturn = await APIRequest(request);

		if (apiRequestReturn.response?.ok) {
			passwordResetToken = token;
			return apiRequestReturn;
		}
		if (apiRequestReturn.response?.status === 500) {
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: `Application Error. Password reset not performed.`,
				}),
			);
			void dispatch(navigate('#'));
			return apiRequestReturn;
		}

		dispatch(
			showMainBanner(NotificationErrorType.ERROR, {
				title: `Password reset token expired or invalid.`,
			}),
		);
		void dispatch(navigate('#'));
		return apiRequestReturn;
	};

export const submitPasswordReset =
	(password: string): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch) => {
		if (!passwordResetToken) {
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: `Application Error. Password reset not performed.`,
				}),
			);
			throw new Error('Password reset token not set');
		}
		const request = new Request(
			new URL(
				ConfigUserManagementApi.submitPasswordReset(passwordResetToken),
				APIConfig.endpointURLBase,
			).href,
			{
				method: HTTPMethod.PUT,
				headers: new Headers({ ...getAPIHeaders() }),
				body: JSON.stringify({ password }),
			},
		);
		const apiRequestReturn = await APIRequest(request);

		try {
			if (apiRequestReturn.apiError) {
				throw new Error(`error submitting password reset: ${apiRequestReturn.apiError}`);
			}
			const authority: AuthorityDto = (await apiRequestReturn.response?.json()) as AuthorityDto;
			if (authority.accessToken == null) {
				dispatch(
					showMainBanner(NotificationErrorType.ERROR, {
						title: `Application Error. Password reset not performed.`,
					}),
				);
				throw new Error(`error no access token`);
			}
			setJwt(authority);
			dispatch({
				type: UserActionType.SET_USER_PERMISSIONS,
				authority,
			});
			const landingPage = selectLandingPageForUser();
			void dispatch(navigate(landingPage));
		} catch (error) {
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: `Application Error. Password reset not performed.`,
				}),
			);
			return apiRequestReturn;
		}

		dispatch(
			showMainBanner(NotificationErrorType.SUCCESS, { title: `Your password has been reset.` }),
		);
		return apiRequestReturn;
	};
