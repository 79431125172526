import { css } from 'lit';
import { jwtKeyName } from '@/config/ConfigCARSx';

const Authorization = class {
	//  link to data store
	static get JWT(): string | null {
		return window.localStorage.getItem(jwtKeyName());
	}

	static set JWT(val: string | null) {
		if (val === null) {
			window.localStorage.removeItem(jwtKeyName());
			return;
		}
		window.localStorage.setItem(jwtKeyName(), val);
	}

	static logout() {
		window.localStorage.removeItem(jwtKeyName());
	}
};

export default Authorization;
