import { css } from 'lit';
import { CSSResultArray, LitElement, PropertyValues, TemplateResult, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { connect } from 'pwa-helpers/connect-mixin';
import { DebuggingConfig } from '@config/ConfigCARSx';
import utilityClassesCss from '@components/css/utilityClasses.css';
import store, { RootState } from '../../redux/redux-store';
import scss from '../../utils/scssAsCss';
import BButtonBase from '../css/lib/bbuttonbase.scss';
import CSSReset from '../css/lib/reset.scss';
import SharedStyles from '../css/shared.scss';
import remEventFormStyles from '../rem/rem-event-form-styles';
import { Banner, BannerLocation, NotificationErrorType } from '../../../typings/shared-types';
import { UiState, getBanners, hideNotificationBanner } from '../../redux/redux-ui';

@customElement('notification-banner')
export default class IrisixNotificationBanner extends connect(store)(LitElement) {
	@state() private banners: UiState['banners'];

	@state() private errorContent?: TemplateResult[];

	@state() private successContent?: TemplateResult[];

	@state() private warningContent?: TemplateResult[];

	@state() private timeouts: { id: number; timeout: number }[] = [];

	public static override get styles(): CSSResultArray {
		return [
			CSSReset,
			BButtonBase,
			SharedStyles,
			remEventFormStyles,
			utilityClassesCss,
			css`
:host {
  background-color: white;
}

#notification-banners {
  z-index: 999;
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
@media (min-width: 820px) {
  #notification-banners {
    width: 75%;
  }
}

.ERROR, .WARNING, .SUCCESS {
  margin-top: 16px;
  font-size: 1.6rem;
  box-sizing: border-box;
  padding: 0;
  backdrop-filter: blur(10px);
  border-radius: 4px;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.15));
  width: 100%;
}
.ERROR.ERROR, .WARNING.ERROR, .SUCCESS.ERROR {
  border: 1px solid rgb(var(--brand-status-danger));
  background-color: rgba(var(--brand-status-danger-background), 0.9);
  color: rgb(var(--brand-status-danger));
  padding: 1.5rem;
  fill: rgb(var(--brand-status-danger));
}
.ERROR.WARNING, .WARNING.WARNING, .SUCCESS.WARNING {
  border: 1px solid rgb(var(--brand-status-caution));
  color: rgb(var(--brand-status-caution));
  background-color: rgba(var(--brand-status-caution-background), 0.9);
  padding: 1.5rem;
  fill: rgb(var(--brand-status-caution));
}
.ERROR.SUCCESS, .WARNING.SUCCESS, .SUCCESS.SUCCESS {
  border: 1px solid rgb(var(--brand-status-good));
  background-color: rgba(var(--brand-status-good-background), 0.9);
  color: rgb(var(--brand-status-good));
  padding: 1.5rem;
  fill: rgb(var(--brand-status-good));
}
.ERROR h3, .WARNING h3, .SUCCESS h3 {
  font-size: 1.5rem;
  font-weight: bold;
}
.ERROR ul, .WARNING ul, .SUCCESS ul {
  list-style-type: disc;
  list-style-position: inside;
}
.ERROR a, .WARNING a, .SUCCESS a {
  font-weight: bold;
  text-decoration: underline;
}
.ERROR .message-area div, .WARNING .message-area div, .SUCCESS .message-area div {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
}
.ERROR .message-area div button, .WARNING .message-area div button, .SUCCESS .message-area div button {
  flex-basis: 35px;
  padding: 2px 0 0 0;
  width: 16px;
  height: 16px;
}
.ERROR .message-area div .right, .WARNING .message-area div .right, .SUCCESS .message-area div .right {
  margin-left: auto;
}
.ERROR .message-area div .close-icon, .WARNING .message-area div .close-icon, .SUCCESS .message-area div .close-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  /* fill: rgb(var(--brand-grey)); */
}
.ERROR .message-area div span, .WARNING .message-area div span, .SUCCESS .message-area div span {
  flex-basis: auto;
}
`,
		];
	}

	public override render(): TemplateResult {
		return html`
			<div id="notification-banners">
				${this.errorContent?.length
					? html`<div class="ERROR">
							<div class="message-area">${this.errorContent}</div>
					  </div>`
					: html``}
				${this.successContent?.length
					? html`<div class="SUCCESS">
							<div class="message-area">${this.successContent}</div>
					  </div>`
					: html``}
				${this.warningContent?.length
					? html`<div class="WARNING">
							<div class="message-area">${this.warningContent}</div>
					  </div>`
					: html``}
			</div>
		`;
	}

	public override stateChanged(state: RootState): void {
		this.banners = getBanners(state, BannerLocation.MAIN);
	}

	protected override updated(props: PropertyValues): void {
		if (props.has('banners')) {
			this.processBanners(NotificationErrorType.ERROR, this.banners);
			this.processBanners(NotificationErrorType.SUCCESS, this.banners);
			this.processBanners(NotificationErrorType.WARNING, this.banners);
		}
	}

	private onNotificationTimeout(id: number): void {
		store.dispatch(hideNotificationBanner(id));
	}

	private processBanners(type: NotificationErrorType, banners: UiState['banners']): void {
		let templateResults: TemplateResult[] = [];

		if (banners === undefined) {
			return;
		}

		if (banners[type].length) {
			templateResults = banners[type].map((notification: Banner) => {
				const idIndex = this.timeouts.findIndex((obj) => obj.id === notification.id);
				if (notification.timeout && idIndex === -1) {
					const itemTimeout = setTimeout(
						this.onNotificationTimeout,
						notification.timeout,
						notification.id,
					);
					this.timeouts.push({ id: notification.id, timeout: itemTimeout });
				}
				return html`
					<div id="${notification.id}">
						<span>
							<h3 class="overflow-wrap-anywhere">${notification.messaging.title}</h3>
							${Array.isArray(notification.messaging.messages)
								? html`
										<ul>
											${notification.messaging.messages.map(
												(message) => html`<li class="overflow-wrap-anywhere">${message}</li>`,
											)}
										</ul>
								  `
								: ``}
						</span>
						<button class="bbuttonbase hoverable right" @click=${this.removeNotification}>
							<svg class="close-icon" role="presentation">
								<use href="img/sprites.svg#close-icon"></use>
							</svg>
						</button>
					</div>
				`;
			});
		}
		switch (type) {
			case NotificationErrorType.ERROR:
				this.errorContent = templateResults;
				break;

			case NotificationErrorType.SUCCESS:
				this.successContent = templateResults;
				break;

			case NotificationErrorType.WARNING:
				this.warningContent = templateResults;
				break;

			default:
				if (DebuggingConfig.showConsoleLogs) {
					console.warn(`notification-banner: unknown notification type "${type as string}"`);
				}
		}
	}

	private removeNotification(e: MouseEvent): void {
		const rawId = (e.currentTarget as HTMLButtonElement)?.parentElement?.id;

		if (!rawId) {
			return;
		}

		const notificationID = parseInt(rawId, 10);
		const idIndex = this.timeouts.findIndex((obj) => obj.id === notificationID);

		if (idIndex !== -1) {
			clearTimeout(this.timeouts[idIndex].timeout);
		}

		store.dispatch(hideNotificationBanner(notificationID));
	}
}
