import { css } from 'lit';
/* eslint-disable max-lines */
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import isEqual from 'lodash-es/isEqual';
import { parse } from 'wellknown';
import {
	AreaDto,
	CameraDto,
	LaneImpactValue,
	LocationDto,
	NamedPointDto,
	RampDto,
	RoadEventDto,
	RouteDto,
	getEmptyLaneBlockage,
} from '../../../typings/api';
import ConfigCARSx, { APIConfig, DebuggingConfig } from '../../config/ConfigCARSx';
import { ConfigREMApi } from '../../config/ConfigREM';
import { AppSection } from '../../constants';
import { isValidNumber, isValidString } from '../../utils/utils';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { navigate } from '../redux-routing';
import { GetState, ThunkActionRoot, ThunkDispatchRoot } from '../redux-store';
import { REMActionType } from './rem-actions';
import { setREMDraftEventProp } from './rem-actions-event';
import REMState from './rem-state';
import { showMainBanner } from '../redux-ui';
import { NotificationErrorType } from '../../../typings/shared-types';

export interface SetRoutesFilterParam extends Action<typeof REMActionType.SET_ROUTES_FILTER_PARAM> {
	path: string;
	value?: string;
}

export type ResetLocationDetails = Action<typeof REMActionType.RESET_LOCATION_DETAILS>;

export type GetRoutesByLocationMeta = Action<typeof REMActionType.GET_ROUTES_BY_LOCATION_META>;

export interface SetMileMarkersValidForRoute
	extends Action<typeof REMActionType.SET_MILE_MARKERS_VALID_FOR_ROUTE> {
	mileMarkersValidForRoute: boolean;
}

export interface SetRoutesByLocationMeta
	extends Action<typeof REMActionType.SET_ROUTES_BY_LOCATION_META> {
	filteredRoutes: REMState['filteredRoutes'];
}

/* eslint-disable camelcase */
export interface GetLocationDetails extends Action<typeof REMActionType.GET_LOCATION_DETAILS> {
	route_designator: RoadEventDto['route'];
	miles_along_route: RoadEventDto['startMileMarker'];
	end_miles: RoadEventDto['endMileMarker'];
}
/* eslint-enable camelcase */
/* eslint-disable camelcase */

export interface GetNearbyCameras extends Action<typeof REMActionType.GET_REM_NEARBY_CAMERAS> {
	route_designator: RoadEventDto['route'];
	miles_along_route: RoadEventDto['startMileMarker'];
}
/* eslint-enable camelcase */

export interface SetNearbyCameras extends Action<typeof REMActionType.SET_REM_NEARBY_CAMERAS> {
	nearbyCameras: RoadEventDto['nearbyCameras'];
}

export interface GetDraftEventRouteExtent
	extends Action<typeof REMActionType.GET_REM_DRAFT_EVENT_ROUTE_EXTENT> {
	route: string;
	startMileMarker: number;
	endMileMarker: number;
}

export interface SetDraftEventRouteExtent
	extends Action<typeof REMActionType.SET_REM_DRAFT_EVENT_ROUTE_EXTENT> {
	geometry?: GeoJSON.Geometry;
}

export interface SetDraftEventRouteGeometry
	extends Action<typeof REMActionType.SET_REM_DRAFT_EVENT_ROUTE_GEOMETRY> {
	geometry?: GeoJSON.Geometry;
}

export const resetLocationDetails = (): ResetLocationDetails => ({
	type: REMActionType.RESET_LOCATION_DETAILS,
});

export const setDraftEventGeometry = (geometry?: GeoJSON.Geometry): SetDraftEventRouteExtent => ({
	type: REMActionType.SET_REM_DRAFT_EVENT_ROUTE_EXTENT,
	geometry,
});

export const setDraftEventRouteGeometry = (
	geometry?: GeoJSON.Geometry,
): SetDraftEventRouteGeometry => ({
	type: REMActionType.SET_REM_DRAFT_EVENT_ROUTE_GEOMETRY,
	geometry,
});

export interface SetNamedPointsForRoute
	extends Action<typeof REMActionType.SET_REM_NAMED_POINTS_FOR_ROUTE> {
	route: string;
	namedPoints: NamedPointDto[];
}

export interface SetAreas extends Action<typeof REMActionType.SET_REM_AREAS> {
	areas: AreaDto[];
}

export interface SetRoutesWithRamps extends Action<typeof REMActionType.SET_REM_ROUTES_WITH_RAMPS> {
	routes: string[];
}

export interface AddRampsToRecord extends Action<typeof REMActionType.ADD_RAMPS_TO_RECORD> {
	route: string;
	ramps: RampDto[];
}

export const setRoutesWithRamps = (routes: string[]): SetRoutesWithRamps => ({
	type: REMActionType.SET_REM_ROUTES_WITH_RAMPS,
	routes,
});

export const addRampsToRecord = (route: string, ramps: RampDto[]): AddRampsToRecord => ({
	type: REMActionType.ADD_RAMPS_TO_RECORD,
	route,
	ramps,
});

export const getRoutesByLocationMeta =
	(county?: string, district?: string): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch: ThunkDispatchRoot): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_ROUTES_BY_LOCATION_META,
			county,
			district,
		});
		const url = new URL(ConfigREMApi.routesByCountyDistrict(), APIConfig.endpointURLBase);
		if (isValidString(county) && county !== undefined) {
			url.searchParams.set('county', county);
		}
		if (isValidString(district) && district !== undefined) {
			url.searchParams.set('district', district);
		}
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		try {
			const filteredRoutes: RouteDto[] = (await apiRequestReturn.response
				?.clone()
				.json()) as RouteDto[];
			dispatch({
				type: REMActionType.SET_ROUTES_BY_LOCATION_META,
				filteredRoutes,
			});
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(
					`error fetching routes in county "${county ?? '<no county specified>'}" & district "${
						district ?? '<no district specified>'
					}"`,
					error,
				);
			}
		}
		return apiRequestReturn;
	};

export const setRoutesFilterParam =
	(path: string, value?: string): ThunkActionRoot<Promise<APIRequestReturn>> =>
	(dispatch: ThunkDispatchRoot, getState: GetState): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.SET_ROUTES_FILTER_PARAM,
			path,
			value,
		});
		const routeFilterParams = {
			county: undefined,
			district: undefined,
			...(getState().rem.routeFilterParams ?? {}),
			[path]: value,
		};
		const { county, district } = routeFilterParams;
		return dispatch(getRoutesByLocationMeta(county, district));
	};

/* eslint-disable camelcase */
export const getLocationDetails =
	(
		route_designator: RoadEventDto['route'],
		miles_along_route: RoadEventDto['startMileMarker'],
		end_miles?: RoadEventDto['endMileMarker'],
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch, getState): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_LOCATION_DETAILS,
			route_designator,
			miles_along_route,
			end_miles,
		});

		const url = new URL(ConfigREMApi.countyDistrict(route_designator), APIConfig.endpointURLBase);
		url.searchParams.set('miles_along_route', miles_along_route.toString());
		if (end_miles) {
			url.searchParams.set('end_miles', end_miles.toString());
		}
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		try {
			const locationDetails: LocationDto = (await apiRequestReturn.response
				?.clone()
				.json()) as LocationDto;
			const { draftEvent } = getState().rem;
			// reset the affected lane data if the details have changed
			if (
				isEqual(draftEvent?.locationDetails?.positiveLanes, locationDetails.positiveLanes) ===
					false ||
				isEqual(draftEvent?.locationDetails?.negativeLanes, locationDetails.negativeLanes) === false
			) {
				//	pos
				await dispatch(setREMDraftEventProp('positiveLaneBlockage', getEmptyLaneBlockage()));
				await dispatch(setREMDraftEventProp('positiveLaneBlockageType', LaneImpactValue.NA));
				//	neg
				await dispatch(setREMDraftEventProp('negativeLaneBlockage', getEmptyLaneBlockage()));
				await dispatch(setREMDraftEventProp('negativeLaneBlockageType', LaneImpactValue.NA));
			}
			void dispatch(setREMDraftEventProp('locationDetails', locationDetails));
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(
					`error parsing event location for route ${route_designator} at milemarker ${miles_along_route}:`,
					error,
				);
			}
		}
		return apiRequestReturn;
	};
/* eslint-enable camelcase */

/* eslint-disable camelcase */
export const getNearbyCameras =
	(
		route_designator: RoadEventDto['route'],
		miles_along_route: RoadEventDto['startMileMarker'],
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_REM_NEARBY_CAMERAS,
			route_designator,
			miles_along_route,
		});

		const url = new URL(ConfigREMApi.nearbyCameras(route_designator), APIConfig.endpointURLBase);
		url.searchParams.set('miles_along_route', miles_along_route.toString());
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		try {
			const nearbyCameras: CameraDto[] = (await apiRequestReturn.response
				?.clone()
				.json()) as CameraDto[];
			void dispatch(setREMDraftEventProp('nearbyCameras', nearbyCameras));
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(
					`error parsing nearby cameras for route ${route_designator} at milemarker ${miles_along_route}:`,
					error,
				);
			}
		}
		return apiRequestReturn;
	};

export const getREMEventRouteExtent =
	(
		route: string,
		startMileMarker: number,
		endMileMarker: number,
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const url = new URL(ConfigREMApi.routeExtent(), APIConfig.endpointURLBase);

		url.searchParams.set('route_designator', route);
		url.searchParams.set('start_mile_marker', startMileMarker.toString());
		if (!Number.isNaN(endMileMarker)) {
			url.searchParams.set('end_mile_marker', endMileMarker.toString());
		}

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		if (!apiRequestReturn?.response) {
			console.warn(
				`Failed to fetch route extent for event at ${route} mm ${startMileMarker} - ${endMileMarker}`,
			);
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const geometry = await apiRequestReturn.response.text();
				dispatch(setDraftEventGeometry(parse(geometry) as GeoJSON.Geometry));
			} catch (error) {
				// dispatch({
				// 	type: DMSActionType.SET_SIGNS_ERROR_STATE,
				// 	apiError: APIError.ResponseUnparseable,
				// });
			}
		} else {
			console.warn(
				`Failed to fetch route extent for event at ${route} mm ${startMileMarker} - ${endMileMarker}`,
			);
		}

		return apiRequestReturn;
	};
/* eslint-enable camelcase */

export const setMileMarkersValidForRoute = (
	mileMarkersValidForRoute: boolean,
): SetMileMarkersValidForRoute => ({
	type: REMActionType.SET_MILE_MARKERS_VALID_FOR_ROUTE,
	mileMarkersValidForRoute,
});

export interface SetREMDraftEventRouteGeometry
	extends Action<REMActionType.SET_REM_DRAFT_EVENT_ROUTE_GEOMETRY> {
	geometry?: GeoJSON.Geometry;
}

export const getREMEventRouteGeometry =
	(route: string): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const url = new URL(ConfigREMApi.codedRoadway(route), APIConfig.endpointURLBase);

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		if (!apiRequestReturn?.response) {
			console.warn(`Failed to fetch coded roadway at ${route}`);
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const geometry = ((await apiRequestReturn.response.json()) as RouteDto)
					.geometry as unknown as GeoJSON.LineString;

				if (geometry !== undefined) {
					geometry?.coordinates?.forEach((coordinate, index) => {
						if (coordinate.length === 3) {
							geometry.coordinates[index] = coordinate.slice(0, 2);
						}
					});

					dispatch({
						type: REMActionType.SET_REM_DRAFT_EVENT_ROUTE_GEOMETRY,
						geometry,
					});
				}
			} catch (error) {
				console.warn(error);
			}
		} else {
			console.warn(`Failed to fetch coded roadway at ${route}`);
		}

		return apiRequestReturn;
	};
// eslint-disable-next-line jsdoc/require-jsdoc
export async function handleNewLocationDetailsForREMEvent(
	dispatch: ThunkDispatchRoot,
	route?: string,
	startMileMarker?: number,
	endMileMarker?: number,
): Promise<void> {
	//	if we're looking up a location we no longer need to filer routes
	void dispatch(setRoutesFilterParam('county', undefined));
	void dispatch(setRoutesFilterParam('district', undefined));
	//	next, show the results of the location lookup
	const detailsCalls: Promise<APIRequestReturn>[] = [];
	if (isValidString(route) && isValidNumber(startMileMarker)) {
		detailsCalls.push(dispatch(getLocationDetails(route, startMileMarker, endMileMarker)));
		detailsCalls.push(
			dispatch(getREMEventRouteExtent(route, startMileMarker, endMileMarker ?? NaN)),
		);
	}
	if (isValidString(route) && isValidNumber(startMileMarker)) {
		detailsCalls.push(dispatch(getNearbyCameras(route, startMileMarker)));
	}
	if (!isValidString(route) || !isValidNumber(startMileMarker)) {
		dispatch(setDraftEventGeometry(undefined));
	}
	await Promise.all(detailsCalls);
}

export const setNamedPointsForRoute = (
	route: string,
	namedPoints: NamedPointDto[],
): SetNamedPointsForRoute => ({
	type: REMActionType.SET_REM_NAMED_POINTS_FOR_ROUTE,
	route,
	namedPoints,
});

export const getNamedPointsForRoute =
	(route: string): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.namedPoints(route), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		if (!apiRequestReturn?.response) {
			console.warn(`Failed to get named points for route: ${route}`);
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const namedPoints: NamedPointDto[] =
					(await apiRequestReturn.response.json()) as NamedPointDto[];

				dispatch(setNamedPointsForRoute(route, namedPoints));
			} catch (error) {
				console.warn(error);
			}
		} else {
			console.warn(`Failed to get named points for route: ${route}`);
		}

		return apiRequestReturn;
	};

export const setAreas = (areas: AreaDto[]): SetAreas => ({
	type: REMActionType.SET_REM_AREAS,
	areas,
});

export const getAreasByType =
	(areaType = 'CITY'): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.areas(areaType), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		if (!apiRequestReturn?.response) {
			console.warn(`Failed to get areas of type: ${areaType}`);
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const areas: AreaDto[] = (await apiRequestReturn.response.json()) as AreaDto[];

				dispatch(setAreas(areas));
			} catch (error) {
				console.warn(error);
			}
		} else {
			console.warn(`Failed to get areas of type: ${areaType}`);
		}

		return apiRequestReturn;
	};

export const getRoutesWithRamps =
	(): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.routesWithRamps(), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		if (!apiRequestReturn?.response) {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `Failed to get routes with ramps ` },
					2000,
				),
			);
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const routes: string[] = (await apiRequestReturn.response.json()) as string[];

				dispatch(setRoutesWithRamps(routes));
			} catch (error) {
				console.warn(error);
			}
		} else {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `Failed to get routes with ramps ` },
					2000,
				),
			);
		}

		return apiRequestReturn;
	};

export const getRampsForRoute =
	(route: string): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch) => {
		const url = new URL(ConfigREMApi.rampsForRoute(route), APIConfig.endpointURLBase).href;
		const apiRequestReturn = await APIRequest(
			new Request(url, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		if (!apiRequestReturn?.response) {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `Failed to get ramps for route: ${route} ` },
					2000,
				),
			);
		}
		try {
			if (apiRequestReturn.response?.ok === true) {
				const ramps: RampDto[] = (await apiRequestReturn.response.json()) as RampDto[];

				dispatch(addRampsToRecord(route, ramps));

				const multiLineStringGeometry: GeoJSON.GeometryCollection = {
					type: 'GeometryCollection',
					geometries: [],
				};

				for (const ramp of ramps) {
					if (ramp.geometry) {
						multiLineStringGeometry.geometries.push(ramp.geometry as GeoJSON.Geometry);
					}
				}
				dispatch(setDraftEventRouteGeometry(multiLineStringGeometry));

				return apiRequestReturn;
			}
		} catch (error) {
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: `Application error. Failed to get ramps for route: ${route} `,
					messages: [`"${url}" failed to return a valid response.`],
				}),
			);
			DebuggingConfig.showConsoleLogs && console.error(error);
		}

		return apiRequestReturn;
	};

export const getRamp =
	(route: string, rampId: number): ThunkActionRoot<Promise<RampDto | undefined>> =>
	async (dispatch) => {
		const url = new URL(ConfigREMApi.ramp(route, rampId), APIConfig.endpointURLBase).href;
		const apiRequestReturn = await APIRequest(
			new Request(url, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		if (!apiRequestReturn?.response) {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `Failed to get ramp with id ${rampId} on route ${route}` },
					2000,
				),
			);
		}
		try {
			if (apiRequestReturn.response?.ok === true) {
				const ramp: RampDto = (await apiRequestReturn.response.json()) as RampDto;

				return ramp;
			}
		} catch (error) {
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: `Application error. Failed to get ramps with id ${rampId} on route ${route} `,
					messages: [`"${url}" failed to return a valid response.`],
				}),
			);
			DebuggingConfig.showConsoleLogs && console.error(error);
		}

		return undefined;
	};
