import { css } from 'lit';
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { HttpStatusCode } from '@constants';
import { getSessionUUID } from '@utils/SessionStorageUtils';
import { ConfigREMApi } from '../../config/ConfigREM';
import { APIRequestReturn } from '../../redux/APIRequest';
import store, { ThunkActionRoot } from '../../redux/redux-store';
import { getREMEvents, refreshREMEvent } from '../../redux/rem/rem-actions-event';
import { getSign, getSignQueue } from '../../redux/rem/rem-actions-event-dms';
import { setREMEventCurrentEditors } from '../../redux/rem/rem-actions-event-editors';
import { selectCurrentDetailsChanged } from '../../redux/rem/rem-selectors';

//	get all events

let REMEventsPollingTimeout: ReturnType<typeof setInterval>;
let REMEventsLastAPIRequestReturn: APIRequestReturn;

function REMPollingVisibilityChangeHandler() {
	if (!document.hidden) {
		// eslint-disable-next-line promise/prefer-await-to-then
		void store.dispatch(getREMEvents()).then((ret) => {
			REMEventsLastAPIRequestReturn = ret;
			return ret;
		});
	}
}
export const stopPollingREMEvents = (autoAbort = true): void => {
	window.clearInterval(REMEventsPollingTimeout);
	if (autoAbort && REMEventsLastAPIRequestReturn) {
		REMEventsLastAPIRequestReturn.abortController?.abort();
	}
	document.removeEventListener('visibilitychange', REMPollingVisibilityChangeHandler);
};

const POLLING_DELAY_PADDING_MS = 2000;

export const startPollingREMEvents =
	(pollingDelayMs: number, pollImmediately = true): ThunkActionRoot<Promise<void>> =>
	async (dispatch, getState): Promise<void> => {
		const { eventsLastPolled } = getState().rem;

		if (
			pollImmediately ||
			!eventsLastPolled ||
			Date.now() - eventsLastPolled - POLLING_DELAY_PADDING_MS > pollingDelayMs
		) {
			REMEventsLastAPIRequestReturn = await dispatch(getREMEvents());
		}
		window.clearInterval(REMEventsPollingTimeout);

		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		REMEventsPollingTimeout = setInterval(async (): Promise<void> => {
			REMEventsLastAPIRequestReturn = await dispatch(getREMEvents());
			if (REMEventsLastAPIRequestReturn.response?.status === HttpStatusCode.UNAUTHORIZED) {
				stopPollingREMEvents();
			}
		}, pollingDelayMs);
		document.addEventListener('visibilitychange', REMPollingVisibilityChangeHandler);
	};

//	get one event

let REMEventPollingEnabled = false;
let REMEventPollingTimeout: ReturnType<typeof setTimeout>;
let REMEventPollingDelayMs: number;
let REMEventLastAPIRequestReturn: APIRequestReturn;
let REMEventPollingId: number;

export const pollREMEvent = async (): Promise<void> => {
	REMEventLastAPIRequestReturn = await store.dispatch(refreshREMEvent(REMEventPollingId));
	if (REMEventLastAPIRequestReturn.response?.status === HttpStatusCode.UNAUTHORIZED) {
		stopPollingREMEvent();
	}
	if (REMEventPollingEnabled === true) {
		REMEventPollingTimeout = setTimeout(() => {
			void pollREMEvent();
		}, REMEventPollingDelayMs);
	}
};

export const stopPollingREMEvent = (autoAbort = true): void => {
	REMEventPollingEnabled = false;
	window.clearTimeout(REMEventPollingTimeout);
	if (autoAbort && REMEventLastAPIRequestReturn) {
		REMEventLastAPIRequestReturn.abortController?.abort();
	}
};

export const startPollingREMEvent = async (
	eventId: number,
	pollingDelayMs: number,
	pollImmediately = true,
) => {
	if (REMEventPollingEnabled === true) {
		return; //	no overlapping polling!
	}
	REMEventPollingEnabled = true;
	REMEventPollingDelayMs = pollingDelayMs;
	REMEventPollingId = eventId;
	if (pollImmediately) {
		await pollREMEvent();
	} else {
		REMEventPollingTimeout = setTimeout(() => {
			void pollREMEvent();
		}, REMEventPollingDelayMs);
	}
};

//	get one sign

let REMSignPollingEnabled = false;
let REMSignPollingTimeout: ReturnType<typeof setTimeout>;
let REMSignPollingDelayMs: number;
let REMSignLastAPIRequestReturn: APIRequestReturn;
let REMSignPollingId: number;

export const pollREMSign = async (): Promise<void> => {
	//	TODO: why is api return request reused this way
	let apiRequestReturn = await store.dispatch(getSign(REMSignPollingId));
	apiRequestReturn = await store.dispatch(getSignQueue(REMSignPollingId));
	REMSignLastAPIRequestReturn = apiRequestReturn;

	if (REMSignLastAPIRequestReturn.response?.status === HttpStatusCode.UNAUTHORIZED) {
		stopPollingREMSign();
	}
	if (REMSignPollingEnabled === true) {
		REMSignPollingTimeout = setTimeout(() => {
			void pollREMSign();
		}, REMSignPollingDelayMs);
	}
};

export const stopPollingREMSign = (autoAbort = true): void => {
	REMSignPollingEnabled = false;
	window.clearTimeout(REMSignPollingTimeout);
	if (autoAbort && REMSignLastAPIRequestReturn) {
		REMSignLastAPIRequestReturn.abortController?.abort();
	}
};

export const startPollingREMSign = (
	signId: number,
	pollingDelayMs: number = ConfigREMApi.signPollingRate,
	pollImmediately = true,
): void => {
	if (REMSignPollingEnabled === true) {
		return; //	no overlapping polling!
	}
	REMSignPollingEnabled = true;
	REMSignPollingDelayMs = pollingDelayMs;
	REMSignPollingId = signId;
	if (pollImmediately) {
		void pollREMSign();
	} else {
		REMSignPollingTimeout = setTimeout(() => {
			void pollREMSign();
		}, REMSignPollingDelayMs);
	}
};

//	register current user

//	register current user

let REMHeartbeatEnabled = false;
let REMHeartbeatTimeout: ReturnType<typeof setTimeout>;
let REMHeartbeatDelayMs: number;
let REMHeartbeatLastAPIRequestReturn: APIRequestReturn;
let REMHeartbeatEventId: number;
let REMHeartbeatISReadOnly: boolean;

export const sendREMHeartbeat = async (isReadOnly = false): Promise<void> => {
	// don't alert other users that a change might be coming to their form if this form is out of date already
	const currentDetailsChanged = selectCurrentDetailsChanged(store.getState());
	if (!isReadOnly && !currentDetailsChanged) {
		REMHeartbeatLastAPIRequestReturn = await store.dispatch(
			setREMEventCurrentEditors(REMHeartbeatEventId, getSessionUUID()),
		);
		if (REMHeartbeatLastAPIRequestReturn.response?.status === HttpStatusCode.UNAUTHORIZED) {
			stopREMHeartbeat();
		}
	}
	if (REMHeartbeatEnabled === true) {
		REMHeartbeatTimeout = setTimeout(() => {
			void sendREMHeartbeat(isReadOnly);
		}, REMHeartbeatDelayMs);
	}
};

function REMHeartbeatVisibilityChangeHandler() {
	const currentDetailsChanged = selectCurrentDetailsChanged(store.getState());
	if (!document.hidden && !REMHeartbeatISReadOnly && !currentDetailsChanged) {
		void store
			.dispatch(setREMEventCurrentEditors(REMHeartbeatEventId, getSessionUUID()))
			.then((ret) => {
				REMHeartbeatLastAPIRequestReturn = ret;
				return ret;
			});
	}
}

export const stopREMHeartbeat = (autoAbort = true): void => {
	REMHeartbeatEnabled = false;
	window.clearTimeout(REMHeartbeatTimeout);
	if (autoAbort && REMHeartbeatLastAPIRequestReturn) {
		REMHeartbeatLastAPIRequestReturn.abortController?.abort();
	}
	document.removeEventListener('visibilitychange', REMHeartbeatVisibilityChangeHandler);
};

export const startREMHeartbeat = (eventId: number, delay: number, isReadOnly = false): void => {
	if (REMHeartbeatEnabled === true) {
		return; //	no overlapping polling!
	}
	REMHeartbeatEnabled = true;
	REMHeartbeatDelayMs = delay;
	REMHeartbeatEventId = eventId;
	REMHeartbeatISReadOnly = isReadOnly;
	void sendREMHeartbeat(isReadOnly);

	document.addEventListener('visibilitychange', REMHeartbeatVisibilityChangeHandler);
};
