import { css } from 'lit';
import { MetricsEventsSearchParams, MetricsHelpersSearchParams } from '../../typings/api';
import { MILLISECONDS_PER_SECOND, HH_COLUMNS, REM_EVENT_COLUMNS } from '../constants';

export type MetricsEventSearchParamsConfig = {
	[k in keyof MetricsEventsSearchParams]-?: {
		defaultValue: MetricsEventsSearchParams[k];
		active: boolean;
	};
};

export const ConfigMetrics = {
	eventSearchParams: {
		'event-types': {
			defaultValue: [],
			active: true,
		},
		'event-sources': {
			defaultValue: [],
			active: true,
		},
		'routes': {
			defaultValue: [],
			active: true,
		},
		'districts': {
			defaultValue: [],
			active: true,
		},
		'attributes': {
			defaultValue: [],
			active: true,
		},
		'responding-units': {
			defaultValue: [],
			active: true,
		},
		'counties': {
			defaultValue: [],
			active: true,
		},
		'responding-unit-dispositions': {
			defaultValue: [],
			active: true,
		},
		'start': {
			defaultValue: NaN,
			active: true,
		},
		'end': {
			defaultValue: NaN,
			active: true,
		},
		'event-id': {
			defaultValue: NaN,
			active: true,
		},
		'start-date': {
			defaultValue: NaN,
			active: true,
		},
		'end-date': {
			defaultValue: NaN,
			active: true,
		},
		'page-num': {
			defaultValue: 1,
			active: true,
		},
		'page-size': {
			defaultValue: 25,
			active: true,
		},
		'sort-field': {
			defaultValue: 'updated',
			active: true,
		},
		'sort-dir': {
			defaultValue: 'asc',
			active: true,
		},
		'created-by': {
			defaultValue: '',
			active: true,
		},
		'license-plate': {
			defaultValue: '',
			active: true,
		},
	} as MetricsEventSearchParamsConfig,
	defaultHelperFilters: {
		'names': [],
		'start-time': NaN,
		'end-time': NaN,
	} as MetricsHelpersSearchParams,
	noSearchPerformed: 'There are no results to show. Please perform a search.',
	noSearchResults: 'There are no results for your search',
	table: {
		pagination: [15, 25, 50, 100],
		defaultPagination: 25,
		defaultColumns: [
			'respondingUnits',
			'timeArrived',
			'timeAssigned',
			'timeCompleted',
			'type',
			'id',
			'route',
			'milemarker',
			'direction',
			'county',
			'district',
			'dateCreated',
			'attributes',
			'lastUpdated',
		] as typeof REM_EVENT_COLUMNS[number][],
	},
	hhTable: {
		pagination: [15, 25, 50, 100],
		defaultPagination: 25,
		defaultColumns: [
			'name',
			'status',
			'disposition',
			'commission',
			'zone',
			'GPS',
			'REM',
			'dashcam',
			'ptzcam',
		] as typeof HH_COLUMNS[number][],
	},
};

export const ConfigMetricsApi = {
	events: `metrics/events/completed`,
	helpers: `metrics/highway-helper/active`,
	event: (eventId: number): string => `events/${eventId}`,
	eventTimeline: (eventId: number): string => `events/${eventId}/timeline`,
	helper: (helperId: number): string => `highway-helper/${helperId}`,
	helperTimeline: (helperId: number): string => `highway-helper/${helperId}/timeline`,
	endpointTimeoutMs: 60 * MILLISECONDS_PER_SECOND,
};

export default ConfigMetrics;
