import { css } from 'lit';
import { css } from 'lit';

export default css`
	.spinner {
		--bg-color: rgb(var(--brand-primary));
		--spinner-color: white;
		margin-left: var(--ggutter);
		border-radius: 50%;
		width: 3rem;
		height: 3rem;
		animation: spin 2s linear infinite;
	}
	.spinner.dark {
		--bg-color: rgb(var(--brand-grey-light));
		--spinner-color: rgb(var(--brand-secondary));
	}
	.spinner.small {
		width: 2rem;
		height: 2rem;
	}
	.spinner::before,
	.spinner::after {
		content: '';
		position: absolute;
	}
	.spinner::before {
		border-radius: 50%;
		background: linear-gradient(270deg, var(--spinner-color) 0%, var(--spinner-color) 100%) 100%
			100%;
		background-repeat: repeat-x;
		background-size: 50% 50%;
		top: -1px;
		bottom: -1px;
		left: -1px;
		right: -1px;
	}
	.spinner::after {
		background: var(--bg-color);
		border-radius: 50%;
		top: 9%;
		bottom: 9%;
		left: 9%;
		right: 9%;
	}
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
