import { css } from 'lit';
import { APIError } from '../APIRequest';
import {
	CustomMessageDto,
	GraphicDto,
	GroupDto,
	LocationDto,
	MessageDto,
	RouteDto,
	SignDto,
	SignFieldDto,
	SignQueueDto,
} from '../../../typings/api';
import { LatLon } from '../../../typings/shared-types';

export type DmsEntities = { signs: Record<number, SignDto>; groups: Record<number, GroupDto> };
export type DMSState = {
	signsError?: APIError;
	signQueueError?: APIError;
	groupsError?: APIError;
	locationsError?: APIError;

	signIds?: number[];
	groupIds?: number[];
	entities: DmsEntities;

	filterTypes: string[];
	customMessages?: CustomMessageDto[];
	signsLastPolled?: number;

	signGraphics?: GraphicDto[];
	signQueue?: SignQueueDto;
	signQueueSelectedMessage?: MessageDto;

	routes?: RouteDto[];
	locationDetailsDict?: Record<string, LocationDto>;
	routeExtentDict?: Record<string, Array<LatLon>>;

	signFields?: SignFieldDto;

	loading?: boolean;

	signPopupLoading?: boolean;
};

export const DMS_STATE_INITIAL: DMSState = {
	entities: { groups: {}, signs: {} },
	signsError: undefined,
	signQueueError: undefined,
	groupsError: undefined,
	locationsError: undefined,
	customMessages: undefined,
	filterTypes: [],
	signsLastPolled: undefined,
	signGraphics: undefined,
	signQueue: undefined,
	signQueueSelectedMessage: undefined,
	routes: undefined,
	locationDetailsDict: undefined,
	routeExtentDict: undefined,
	signFields: undefined,
	loading: false,
	signPopupLoading: false,
};
