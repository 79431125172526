import { css } from 'lit';
import { Action } from 'redux';
import { ThunkActionRoot } from './redux-store';
import { FilterType } from '../config/config.d';

//	STATE

export interface MapState {
	bounds?: L.LatLngBoundsLiteral; //	what the map shows currently
	moveTo?: L.LatLngLiteral | L.LatLngBoundsLiteral; //	prompts the map to move, after which this is immediately set to undefined
	activeFilters: Set<FilterType['value']>;
}

export const MAP_STATE_INITIAL: MapState = {
	bounds: undefined,
	moveTo: undefined,
	activeFilters: new Set(),
};

//	ACTION TYPING

export enum MapActionType {
	MAP_FIT_TO_BOUNDS = 'MAP_FIT_TO_BOUNDS',
	MAP_MOVE_TO_POINT = 'MAP_MOVE_TO_POINT',
	MAP_CLEAR_DESTINATION = 'MAP_CLEAR_DESTINATION',
	MAP_SET_ACTIVE_FILTERS = 'MAP_SET_ACTIVE_FILTERS',
}

interface MapFitToBounds extends Action<typeof MapActionType.MAP_FIT_TO_BOUNDS> {
	bounds: L.LatLngBoundsLiteral;
}

interface MapMoveToPoint extends Action<typeof MapActionType.MAP_MOVE_TO_POINT> {
	point: L.LatLngLiteral;
}

type MapClearDestination = Action<typeof MapActionType.MAP_CLEAR_DESTINATION>;

interface MapSetActiveFilters extends Action<typeof MapActionType.MAP_SET_ACTIVE_FILTERS> {
	activeFilters: MapState['activeFilters'];
}

export type MapAction = MapFitToBounds | MapMoveToPoint | MapClearDestination | MapSetActiveFilters;

//	ACTIONS

export const mapFitToBounds = (bounds: L.LatLngBoundsLiteral): MapFitToBounds => ({
	type: MapActionType.MAP_FIT_TO_BOUNDS,
	bounds,
});

export const mapMoveToPoint = (point: L.LatLngLiteral): MapMoveToPoint => ({
	type: MapActionType.MAP_MOVE_TO_POINT,
	point,
});

export const mapClearDestination =
	(): ThunkActionRoot<void> =>
	(dispatch): void => {
		dispatch({
			type: MapActionType.MAP_CLEAR_DESTINATION,
		});
	};

export const mapSetActiveFilters = (
	activeFilters: MapState['activeFilters'],
): MapSetActiveFilters => ({
	type: MapActionType.MAP_SET_ACTIVE_FILTERS,
	activeFilters,
});

//	REDUCER

export const MapReducer = (
	state: MapState = MAP_STATE_INITIAL,
	action: MapAction | undefined = undefined,
): MapState => {
	if (action === undefined) {
		return state;
	}
	switch (action.type) {
		case MapActionType.MAP_FIT_TO_BOUNDS:
			return {
				...state,
				moveTo: action.bounds,
			};
		case MapActionType.MAP_MOVE_TO_POINT:
			return {
				...state,
				moveTo: action.point,
			};
		case MapActionType.MAP_CLEAR_DESTINATION:
			return {
				...state,
				moveTo: undefined,
			};
		case MapActionType.MAP_SET_ACTIVE_FILTERS:
			return {
				...state,
				activeFilters: action.activeFilters,
			};
		default:
			return state;
	}
};
