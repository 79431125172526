import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import {
	DraftEvent,
	FrameDto,
	MessageDto,
	PreviewDto,
	PreviewDtoResponse,
	RoadEventDto,
	SignDto,
	SignSelection,
} from '../../../typings/api';
import { APIErrorJSON, NotificationErrorType } from '../../../typings/shared-types';
import ConfigCARSx, { APIConfig, DebuggingConfig } from '../../config/ConfigCARSx';
import { ConfigREMApi } from '../../config/ConfigREM';
import { AppSection } from '../../constants';
import { isAPIErrorJSON } from '../../utils/type-guards';
import { multitagIsJustifyLineCenter, multitagIsJustifyPageMiddle } from '../../utils/utils';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { addItem, removeItem } from '../redux-loaderAnim';
import { navigate } from '../redux-routing';
import store, { ThunkActionRoot, ThunkDispatchRoot } from '../redux-store';
import { showMainBanner } from '../redux-ui';
import { REMActionType } from './rem-actions';
import { prepareDraftEventForPreviewCreation, setREMDraftEventProp } from './rem-actions-event';
import { selectedSignSelections } from './rem-selectors';
import REMState from './rem-state';

export const frameExistsAndIsNotGraphic = (frame?: FrameDto): frame is FrameDto =>
	frame !== null && frame !== undefined && frame.graphicId === undefined;

export const prepareDMSMessagesForClient = (
	eventDMSPreviewMessages: PreviewDto[],
): PreviewDtoResponse => {
	return Object.values(eventDMSPreviewMessages).reduce((acc, message) => {
		let justifyLineCenter = false;
		let justifyPageMiddle = false;
		if (frameExistsAndIsNotGraphic(message.frame1)) {
			//	message-wide formatting multitags only appear on the first frame, but apply to all (text) frames
			justifyLineCenter = multitagIsJustifyLineCenter(message.frame1?.text) ?? false;
			justifyPageMiddle = multitagIsJustifyPageMiddle(message.frame1?.text) ?? false;
		}
		[message.frame2, message.frame3].forEach((frame) => {
			if (frameExistsAndIsNotGraphic(frame) && frame.text) {
				if (justifyLineCenter) {
					frame.text = `[jl3]${frame.text}`;
				}
				if (justifyPageMiddle) {
					frame.text = `[jp3]${frame.text}`;
				}
			}
		});
		if (message?.signId) {
			const preview = acc[message.signId];

			if (preview === undefined || preview === null || Array.isArray(preview) === false) {
				acc[message.signId] = [message];
			} else {
				preview.push(message);
				acc[message.signId] = preview;
			}
		}
		return acc;
	}, {} as PreviewDtoResponse);
};

export const getInitialSignSelectionStateFromDMSMessages = (
	dmsPreview: PreviewDtoResponse,
): SignSelection[] =>
	Object.entries(dmsPreview).reduce((signSelection, [signIdKey, dmsPreviews]) => {
		if (dmsPreviews !== null) {
			signSelection.push({
				signId: parseInt(signIdKey, 10),
				polarity: dmsPreviews.length === 1 ? dmsPreviews[0].polarity : undefined,
			} as SignSelection);
		}
		return signSelection;
	}, [] as SignSelection[]);

export interface GetDMSforREMEvent extends Action<typeof REMActionType.GET_DMS_FOR_REM> {
	event: RoadEventDto;
}

export interface SetDMSforREMEvent extends Action<typeof REMActionType.SET_DMS_PREVIEW> {
	eventDMSPreviewMessages?: PreviewDtoResponse | null;
}

export interface SetDMSLoadingIds extends Action<typeof REMActionType.SET_DMS_PREVIEW_LOADING_IDS> {
	eventDMSSignLoadingIds: number[];
	eventDMSPreviewMessages?: PreviewDtoResponse;
	unsavedDraftEvent?: true;
}

export interface RemoveDMSFromREMEvent
	extends Action<typeof REMActionType.REMOVE_DMS_FROM_REM_EVENT> {
	signId: number;
}

export type GetSign = Action<typeof REMActionType.GET_SIGN>;
export interface SetSign extends Action<typeof REMActionType.REM_SET_SIGN> {
	currentSign: REMState['currentSign'];
}
export type GetSignQueue = Action<typeof REMActionType.GET_SIGN_QUEUE>;
export interface SetSignQueue extends Action<typeof REMActionType.SET_SIGN_QUEUE> {
	currentSignQueue?: MessageDto[];
}

export type PrioritizeMessages = Action<typeof REMActionType.PRIORITIZE_MESSAGES>;

export const setDMSPreview = (eventDMSPreviewMessages?: PreviewDtoResponse): SetDMSforREMEvent => ({
	type: REMActionType.SET_DMS_PREVIEW,
	eventDMSPreviewMessages,
});

const handleUpdatedPreviews = (previews: PreviewDto[]): void => {
	const eventDMSPreviewMessages = prepareDMSMessagesForClient(previews);
	store.dispatch(setDMSPreview(eventDMSPreviewMessages));
	const eventDMSSelectedSigns =
		getInitialSignSelectionStateFromDMSMessages(eventDMSPreviewMessages);
	void store.dispatch(
		setREMDraftEventProp(
			'selectedSigns',
			eventDMSSelectedSigns.length ? eventDMSSelectedSigns : undefined,
		),
	);
};

export const removeDMSPreview =
	(signId: number): ThunkActionRoot<void> =>
	(dispatch: ThunkDispatchRoot, getState): void => {
		dispatch({
			type: REMActionType.REMOVE_DMS_FROM_REM_EVENT,
			signId,
		});
		const signSelections = selectedSignSelections(getState());
		void dispatch(setREMDraftEventProp('selectedSigns', signSelections));
	};

export const getDMSforREMEvent =
	(event: DraftEvent, forceNewPreviews?: true): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch: ThunkDispatchRoot, getState): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_DMS_FOR_REM,
			event,
		});

		const id = getState().rem.draftEvent?.id;
		/*
	
		draft event with an id has dms already assigned:
			GET getDMSForRemEvent by event id in the URL

		unsaved draft event with no id yet:
			POST dmsPreviewsByREMEvent a DraftEvent in the body

	*/
		const getExistingPreviews = id !== undefined && !forceNewPreviews;
		const url = getExistingPreviews
			? new URL(ConfigREMApi.getDMSForRemEvent(id), APIConfig.endpointURLBase)
			: new URL(ConfigREMApi.dmsPreviewsByREMEvent(), APIConfig.endpointURLBase);
		const preparedDraftEvent = prepareDraftEventForPreviewCreation(event);
		const method = getExistingPreviews ? HTTPMethod.GET : HTTPMethod.POST;
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: getExistingPreviews ? undefined : JSON.stringify(preparedDraftEvent),
			}),
		);

		try {
			const response: PreviewDto[] | APIErrorJSON = (await apiRequestReturn.response
				?.clone()
				.json()) as PreviewDto[];
			if (isAPIErrorJSON(response)) {
				if (DebuggingConfig.showConsoleLogs) {
					console.error(`${response.error}: ${response.message}`);
				}
				dispatch(setDMSPreview(undefined));
			} else {
				handleUpdatedPreviews(response);
			}
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing dms messaging preview for current event:`, error);
			}
		}
		return apiRequestReturn;
	};

export const determineDMSPreviews = (
	newSignIds: number[],
	allEventDMSPreviewMessages?: PreviewDtoResponse,
): SetDMSLoadingIds => {
	if (!allEventDMSPreviewMessages) {
		return {
			type: REMActionType.SET_DMS_PREVIEW_LOADING_IDS,
			eventDMSSignLoadingIds: newSignIds,
		};
	}

	const eventDMSPreviewMessages = Object.entries(allEventDMSPreviewMessages).reduce(
		(acc, [key, previews]) => {
			const signId = parseInt(key, 10);
			if (!previews?.length || newSignIds.includes(signId)) {
				acc[key] = previews;
			}
			return acc;
		},
		{} as PreviewDtoResponse,
	);

	const eventDMSSignLoadingIds = newSignIds.reduce((acc, signId) => {
		if (!allEventDMSPreviewMessages[String(signId)]) {
			acc.push(signId);
		}
		return acc;
	}, new Array<number>());

	return {
		type: REMActionType.SET_DMS_PREVIEW_LOADING_IDS,
		eventDMSSignLoadingIds,
		eventDMSPreviewMessages,
		unsavedDraftEvent: true,
	};
};
export const setDMSSignLoadingPreviewIds = (eventDMSSignLoadingIds: number[]): SetDMSLoadingIds => {
	return {
		type: REMActionType.SET_DMS_PREVIEW_LOADING_IDS,
		eventDMSSignLoadingIds,
	};
};

export const getDMSPreviews =
	(draftEvent: DraftEvent): ThunkActionRoot =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch(addItem('api', 'rem-get-dms-preview'));
		const url = new URL(ConfigREMApi.dmsPreviewsByREMEvent(), APIConfig.endpointURLBase);
		const apiReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(prepareDraftEventForPreviewCreation(draftEvent)),
			}),
		);
		if (apiReturn.response?.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		}
		if (apiReturn.response?.status !== 200) {
			if (DebuggingConfig.showConsoleLogs) {
				console.error('error getting DMS messages for current event.');
			}
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: `There was an error getting the DMS messages for this incident.` },
					5000,
				),
			);

			dispatch(removeItem('api', 'rem-get-dms-preview'));
			return apiReturn;
		}
		try {
			const response: PreviewDto[] | APIErrorJSON = (await apiReturn.response
				?.clone()
				.json()) as PreviewDto[];
			if (isAPIErrorJSON(response)) {
				if (DebuggingConfig.showConsoleLogs) {
					console.error(`${response.error}: ${response.message}`);
				}
				dispatch(setDMSPreview(undefined));
			} else {
				handleUpdatedPreviews(response);
			}
		} catch (error) {
			apiReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing dms messaging preview for current event:`, error);
			}
		}
		dispatch(setDMSSignLoadingPreviewIds([]));
		dispatch(removeItem('api', 'rem-get-dms-preview'));
		return apiReturn;
	};

export const setSign = (currentSign?: SignDto): SetSign => ({
	type: REMActionType.REM_SET_SIGN,
	currentSign,
});

export const getSign =
	(signId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_SIGN,
		});
		const apiRequestReturn: APIRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.getSignById(signId), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		try {
			const currentSign: SignDto = (await apiRequestReturn.response?.clone().json()) as SignDto;
			dispatch(setSign(currentSign));
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing sign #${signId}:`, error);
			}
		}
		return apiRequestReturn;
	};

export const getSignsByIds =
	(signIds: number[]): ThunkActionRoot<Promise<SignDto[]>> =>
	async (dispatch): Promise<SignDto[]> => {
		dispatch({
			type: REMActionType.GET_SIGN,
		});
		const apiRequestReturn: APIRequestReturn[] = await Promise.all(
			signIds.map((signId) =>
				APIRequest(
					new Request(new URL(ConfigREMApi.getSignById(signId), APIConfig.endpointURLBase).href, {
						method: HTTPMethod.GET,
						headers: new Headers({
							...getAPIHeaders(),
						}),
					}),
				),
			),
		);

		if (apiRequestReturn.some((requestReturn) => requestReturn.response?.status === 401)) {
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, { title: `Error fetching one or more signs` }),
			);
			return [];
		}
		const signs = apiRequestReturn.map(
			async (requestReturn) => (await requestReturn.response?.clone().json()) as SignDto,
		);

		return Promise.all(signs);
	};

export const setSignQueue = (currentSignQueue?: MessageDto[]): SetSignQueue => ({
	type: REMActionType.SET_SIGN_QUEUE,
	currentSignQueue,
});

export const getSignQueue =
	(signId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_SIGN_QUEUE,
		});
		const apiRequestReturn: APIRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.getSignQueueById(signId), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		try {
			const signQueue = (await apiRequestReturn.response?.clone().json()) as MessageDto[];
			dispatch(setSignQueue(signQueue));
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing queue for sign #${signId}:`, error);
			}
		}
		return apiRequestReturn;
	};

export const prioritizeMessages =
	(eventId: number, post = false): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.PRIORITIZE_MESSAGES,
		});
		const apiRequestReturn: APIRequestReturn = await APIRequest(
			new Request(
				new URL(ConfigREMApi.prioritizeMessagesForEvent(eventId), APIConfig.endpointURLBase).href,
				{
					method: post ? HTTPMethod.POST : HTTPMethod.GET,
					headers: new Headers({
						...getAPIHeaders(),
					}),
				},
			),
		);

		try {
			const response: PreviewDto[] | APIErrorJSON = (await apiRequestReturn.response
				?.clone()
				.json()) as PreviewDto[];
			if (isAPIErrorJSON(response)) {
				if (DebuggingConfig.showConsoleLogs) {
					console.error(`${response.error}: ${response.message}`);
				}
			} else {
				handleUpdatedPreviews(response);
			}
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`Error prioritizing messages for #${eventId}:`, error);
			}
		}
		return apiRequestReturn;
	};
