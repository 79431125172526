import { css } from 'lit';
import { DebuggingConfig } from '@config/ConfigCARSx';
import { RootAction, RootState, ThunkStoreRoot } from './redux-store';

//	TODO: type this?
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const middlewareErrorCatcher =
	(store: ThunkStoreRoot) =>
	(next: (arg0: RootAction) => RootState) =>
	(action: RootAction, state: RootState = store.getState()): RootState => {
		try {
			return next(action);
		} catch (err) {
			if (DebuggingConfig.showConsoleLogs) {
				// intentional console error
				// eslint-disable-next-line no-console
				console.error(err, action, state);
			}
			return next(action);
		}
	};

export default middlewareErrorCatcher;
