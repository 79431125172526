import { css } from 'lit';
import { locationInfoToKey } from '../../config/ConfigDMS';
import { DMSState, DMS_STATE_INITIAL } from './dms-state';
import { DMSAction, DMSActionType } from './dms-actions';
import { CustomMessageDto } from '../../../typings/api';

// eslint-disable-next-line import/prefer-default-export
export const DMSReducer = (
	state: DMSState = DMS_STATE_INITIAL,
	action: DMSAction | undefined = undefined,
): DMSState => {
	if (action === undefined) {
		return state;
	}
	switch (action.type) {
		case DMSActionType.SET_SIGNS_ERROR_STATE: {
			return {
				...state,
				signsError: action.apiError,
			};
		}
		case DMSActionType.SET_SIGN_QUEUE_ERROR_STATE:
			return {
				...state,
				signQueueError: action.apiError,
			};
		case DMSActionType.SET_GROUPS_ERROR_STATE: {
			return {
				...state,
				groupsError: action.apiError,
			};
		}
		case DMSActionType.SET_SIGNS:
			return {
				...state,
				signsError: undefined,
				signIds: action.signIds,
				entities: { ...state.entities, signs: action?.entities?.signs || {} },
				signsLastPolled: Date.now(),
			};
		case DMSActionType.SET_SIGN: {
			const signs = { ...state.entities.signs };
			signs[action.sign.id] = action.sign;
			return {
				...state,
				signsError: undefined,
				entities: { ...state.entities, signs },
			};
		}
		case DMSActionType.FILTER_TYPES:
			return {
				...state,
				filterTypes: [...action.filterTypes],
			};
		case DMSActionType.SET_GROUPS:
			return {
				...state,
				groupsError: undefined,
				groupIds: action.groupIds,
				entities: { ...state.entities, groups: action?.entities?.groups || {} },
				signsLastPolled: Date.now(),
			};
		case DMSActionType.SAVE_GROUP: {
			return {
				...state,
				groupIds: [
					...(state.groupIds?.filter((id) => id !== action.groupId) ?? []),
					action.groupId,
				],
				entities: {
					...state.entities,
					groups: { ...state.entities.groups, ...action.entities?.groups },
				},
			};
		}
		case DMSActionType.DELETE_GROUPS: {
			const entities = { ...state.entities, groups: { ...state.entities.groups } };
			action.groupIds.forEach((id) => {
				delete entities.groups[id];
			});
			const groupIds = [...(state.groupIds ?? [])].filter((id) => !action.groupIds.includes(id));
			return {
				...state,
				groupsError: undefined,
				groupIds,
				entities,
			};
		}
		case DMSActionType.SET_CUSTOM_MESSAGES:
			return {
				...state,
				signsError: undefined,
				customMessages: action.customMessages,
				signsLastPolled: Date.now(),
			};
		case DMSActionType.SET_DMS_SIGN_QUEUE:
			return {
				...state,
				signQueue: action.signQueue,
			};
		case DMSActionType.SET_SIGN_ACTIVE_QUEUE: {
			const { activeQueue } = action;
			return {
				...state,
				signQueue: { ...state.signQueue, activeQueue },
			};
		}
		case DMSActionType.SET_SIGN_GRAPHICS:
			return {
				...state,
				signGraphics: action.signGraphics,
			};
		case DMSActionType.SET_ROUTES:
			return {
				...state,
				routes: action.routes,
			};
		case DMSActionType.SET_CUSTOM_MESSAGE: {
			let customMessages: CustomMessageDto[] = [];
			if (state.customMessages) {
				customMessages = state.customMessages.filter((msg) => msg.id !== action.customMessage.id);
			}
			return { ...state, customMessages: [...customMessages, action.customMessage] };
		}
		case DMSActionType.SET_LOCATION_DETAILS: {
			const locationDetailsDict = { ...state.locationDetailsDict };
			locationDetailsDict[
				locationInfoToKey(action.route_designator, action.miles_along_route, action.end_miles)
			] = action.locationDetails;

			return {
				...state,
				locationDetailsDict,
			};
		}

		case DMSActionType.SET_ROUTE_EXTENT: {
			const routeExtentDict = { ...state.routeExtentDict };
			routeExtentDict[
				locationInfoToKey(action.route, action.startMileMarker, action.endMileMarker)
			] = action.coords;
			return {
				...state,
				routeExtentDict,
			};
		}
		case DMSActionType.SET_SIGN_FIELDS:
			return {
				...state,
				signFields: action.signFields,
			};
		case DMSActionType.SET_DMS_LOADING:
			return {
				...state,
				loading: action.loading,
			};
		case DMSActionType.SET_LOCATION_ERROR_STATE:
			return {
				...state,
				locationsError: action.apiError,
			};
		case DMSActionType.SET_DMS_POPUP_LOADING:
			return {
				...state,
				signPopupLoading: action.signPopupLoading,
			};
		case DMSActionType.SET_DMS_SIGN_QUEUE_SELECTED_MESSAGE:
			return {
				...state,
				signQueueSelectedMessage: action.selectedMessage,
			};
		default:
			return state;
	}
};
