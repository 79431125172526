import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import { CurrentEditorsDto } from '../../../typings/api';
import { APIConfig, DebuggingConfig } from '../../config/ConfigCARSx';
import { ConfigREMApi } from '../../config/ConfigREM';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { ThunkActionRoot, ThunkDispatchRoot } from '../redux-store';
import { REMActionType } from './rem-actions';
import REMState from './rem-state';

export interface GetREMEventCurrentEditors
	extends Action<typeof REMActionType.GET_REM_EVENT_CURRENT_EDITORS> {
	eventId: number;
}

export interface SetREMEventCurrentEditors
	extends Action<typeof REMActionType.SET_REM_EVENT_CURRENT_EDITORS> {
	currentEditors: REMState['currentEditors'];
}

export const getREMEventCurrentEditors =
	(eventId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch: ThunkDispatchRoot): Promise<APIRequestReturn> => {
		dispatch({
			type: REMActionType.GET_REM_EVENT_CURRENT_EDITORS,
			eventId,
		});
		const url = new URL(ConfigREMApi.currentEditors(eventId), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		try {
			const currentEditors: CurrentEditorsDto = (await apiRequestReturn.response
				?.clone()
				.json()) as CurrentEditorsDto;
			dispatch({
				type: REMActionType.SET_REM_EVENT_CURRENT_EDITORS,
				currentEditors,
			});
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing current editors of event #${eventId} from server:`, error);
			}
		}
		return apiRequestReturn;
	};

export const setREMEventCurrentEditors =
	(eventId: number, sessionUUID: string): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch: ThunkDispatchRoot): Promise<APIRequestReturn> => {
		const url = new URL(ConfigREMApi.currentEditors(eventId), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify({ sessionUUID }),
			}),
		);

		try {
			const currentEditors: CurrentEditorsDto = (await apiRequestReturn.response
				?.clone()
				.json()) as CurrentEditorsDto;
			dispatch({
				type: REMActionType.SET_REM_EVENT_CURRENT_EDITORS,
				currentEditors,
			});
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing current editors of event #${eventId} from server:`, error);
			}
		}
		return apiRequestReturn;
	};

export const clearREMEventCurrentEditors = (eventId: number, sessionUUID: string) => {
	const url = new URL(ConfigREMApi.currentEditors(eventId), APIConfig.endpointURLBase);
	void APIRequest(
		new Request(url.href, {
			method: HTTPMethod.DELETE,
			headers: new Headers({
				...getAPIHeaders(),
			}),
			body: JSON.stringify({ sessionUUID }),
		}),
	);

	const currentEditors: CurrentEditorsDto = {
		roadwayEventId: undefined,
		currentEditors: [],
		updated: undefined,
	};
	return {
		type: REMActionType.SET_REM_EVENT_CURRENT_EDITORS,
		currentEditors,
	};
};
