import { css } from 'lit';
import { NotificationErrorType } from '@typings/shared-types';
import { AppSection, HttpStatusCode } from '@constants';
import ConfigCARSx, { APIConfig, DebuggingConfig } from '../config/ConfigCARSx';
import Authorization from '../rest/Authorization';
import store from './redux-store';
import { showMainBanner } from './redux-ui';
import { navigate } from './redux-routing';

export enum APIError {
	FetchFailed = 'FetchFailed', //	unable to complete fetch request (general)
	FetchTimeout = 'FetchTimeout', //	fetch took too long to complete (network or server)
	ResponseInvalid = 'ResponseInvalid', //	non-ok response (server)
	ResponseUnparseable = 'ResponseUnparseable', //	unparseable reponse (server)
	ResponseEmpty = 'ResponseEmpty', //	empty reponse (server)
	AuthorizationFailed = 'AuthorizationFailed', //	bad or missing JWT (client or server)
	ServerError = 'ServerError', //	server error in response as JSON (server)
}

export type APIRequestReturn = {
	request?: Request;
	response?: Response;
	abortController?: AbortController;
	apiError?: APIError;
};

export const getAPIHeaders = (): HeadersInit => ({
	'Authorization': Authorization.JWT ?? '',
	'Accept': 'application/json',
	'Content-Type': 'application/json',
});

/**
 * @function APIRequest
 * @description performs basic error-handling and cache control configuration that most API interaction requires
 * @param {Request} originalRequest configuration of the request to the API
 * @param {number} timeoutMs how long the request hands before being failed
 * @returns {Promise<Object>}  the request made, the response recieved, any errors encountered, and a reference to the abort controller
 */
export const APIRequest = async (
	originalRequest: Request,
	timeoutMs: number = APIConfig.requestTimeoutMs,
	cacheControlNoStore = true,
): Promise<APIRequestReturn> => {
	const apiRequestReturn: APIRequestReturn = {};
	let fetchTimeout: ReturnType<typeof setTimeout>;
	apiRequestReturn.abortController = new AbortController();
	apiRequestReturn.request = new Request(originalRequest, {
		signal: apiRequestReturn.abortController.signal,
	});
	if (!apiRequestReturn.request.headers.has('Cache-Control') && cacheControlNoStore) {
		apiRequestReturn.request.headers.set('Cache-Control', 'no-store');
	}

	if (timeoutMs) {
		fetchTimeout = setTimeout(() => {
			apiRequestReturn.abortController?.abort();
		}, timeoutMs);
	}

	try {
		apiRequestReturn.response = await fetch(apiRequestReturn.request);
		if (timeoutMs !== undefined && fetchTimeout!) {
			clearTimeout(fetchTimeout);
		}

		if (apiRequestReturn.response.status === HttpStatusCode.UNAUTHORIZED) {
			store.dispatch(
				showMainBanner(NotificationErrorType.WARNING, {
					title: `User session ended. Please login again.`,
				}),
			);
			apiRequestReturn.apiError = APIError.AuthorizationFailed;
			if (DebuggingConfig.showConsoleLogs) {
				console.warn(
					`current user is not authorized to access  "${apiRequestReturn.response.url}"`,
				);
			}
			Authorization.logout();
			await store.dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));

			return apiRequestReturn;
		}
		if (!apiRequestReturn.response.ok) {
			const body = await apiRequestReturn.response.json();
			store.dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: `Application error`,
					messages: [
						`"${originalRequest.url}" returned error code "${apiRequestReturn.response?.status}".`,
						body?.message
							? `message: ${body.message}`
							: body?.error
							? `error: ${body?.error}`
							: 'No error message provided.',
					],
				}),
			);
			DebuggingConfig.showConsoleLogs &&
				console.error(
					`Error response code: ${originalRequest.url}: ${apiRequestReturn.response?.status} - ${apiRequestReturn.response?.body}`,
				);

			apiRequestReturn.apiError = APIError.ResponseInvalid;
			return apiRequestReturn;
		}
		//	any other pre-response-parsing error handling goes here
	} catch (error) {
		if ((error as Error)?.name === 'AbortError') {
			apiRequestReturn.apiError = APIError.FetchTimeout;
			store.dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: `Application or network error`,
					messages: [
						`"${originalRequest.url}" response timed out after ${
							timeoutMs / 1000
						} seconds. Please try again later.`,
					],
				}),
			);
			DebuggingConfig.showConsoleLogs &&
				console.error(
					`Timeout: ${originalRequest.url}: ${apiRequestReturn.response?.status} - ${apiRequestReturn.response?.body}`,
					error,
				);
			return apiRequestReturn;
		}

		apiRequestReturn.apiError = APIError.FetchFailed;

		store.dispatch(
			showMainBanner(NotificationErrorType.ERROR, {
				title: `Application or network error`,
				messages: [`"${originalRequest.url}" failed to return a valid response.`],
			}),
		);
		DebuggingConfig.showConsoleLogs &&
			console.error(
				`${originalRequest.url}: ${apiRequestReturn.response?.status} - ${apiRequestReturn.response?.body}`,
				error,
			);
	}
	return apiRequestReturn;
};
export default APIRequest;
