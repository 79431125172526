import { css } from 'lit';
import {
	AreaDto,
	CurrentEditorsDto,
	DraftEvent,
	EmailGroupsRecipientsDto,
	MessageDto,
	NamedPointDto,
	PreviewDto,
	RampDto,
	RoadEventDto,
	RoadEventFieldDto,
	RoadEventTimelineDto,
	RouteDto,
	SignDto,
	SignSelection,
} from '../../../typings/api';
import { FormSectionsRecord } from '../../config/ConfigREM';
import { APIError } from '../APIRequest';
import { REMAPIAction } from './rem-actions';

export type REMState = {
	events?: RoadEventDto[];
	eventsLastPolled?: number;
	eventFields?: RoadEventFieldDto;
	emailGroupsRecipients?: EmailGroupsRecipientsDto;
	routeFilterParams?: {
		county?: string;
		district?: string;
	};
	filteredRoutes?: RouteDto[];
	eventDMSPreviewMessages?: Record<number, PreviewDto[] | null>;
	eventDMSSignLoadingIds: number[];
	eventDMSSelectedSigns?: SignSelection[];
	draftEvent?: DraftEvent;
	currentDetailsId?: number;
	draftEventIcon?: string;
	draftEventFutureIcon?: string;
	draftEventGeometryString?: GeoJSON.Geometry;
	draftEventDescription?: string;
	draftEventRouteGeometry?: GeoJSON.Geometry;
	initialDraftEvent?: DraftEvent;
	mileMarkersValidForRoute?: boolean;
	readOnlyMode?: boolean;
	unsavedDraftEvent: boolean;
	currentEditors?: CurrentEditorsDto;
	eventTimeline?: RoadEventTimelineDto;
	errors?: {
		[key in REMAPIAction]?: {
			[key in APIError]?: boolean;
		};
	};
	formSections: Partial<FormSectionsRecord<boolean>>;
	currentSign?: SignDto;
	currentSignQueue?: MessageDto[];
	loading?: boolean;
	dispositionError?: boolean;
	unsavedEventProps?: string[];
	shouldRefreshEventMessages: boolean;
	userHasModifiedPriority: boolean;
	userHasModifiedIncludeInEventFeed: boolean;
	namedPoints: Record<string, NamedPointDto[]>;
	cities: AreaDto[];
	eventIcons: Record<number, string>;
	routesWithRamps: string[];
	ramps: Record<string, RampDto[]>;
};
export default REMState;

export const REM_STATE_INITIAL: REMState = {
	events: undefined,
	eventFields: undefined,
	routeFilterParams: undefined,
	filteredRoutes: undefined,
	eventDMSPreviewMessages: undefined,
	eventDMSSignLoadingIds: [],
	eventDMSSelectedSigns: undefined,
	draftEvent: undefined,
	currentDetailsId: undefined,
	draftEventIcon: undefined,
	draftEventFutureIcon: undefined,
	draftEventGeometryString: undefined,
	draftEventDescription: undefined,
	draftEventRouteGeometry: undefined,
	initialDraftEvent: undefined,
	readOnlyMode: false,
	unsavedDraftEvent: false,
	currentEditors: undefined,
	mileMarkersValidForRoute: undefined,
	errors: {},
	formSections: {
		DMS: false,
		RESPONDING_UNITS: false,
		VEHICLES: false,
	},
	currentSign: undefined,
	currentSignQueue: undefined,
	loading: false,
	dispositionError: false,
	unsavedEventProps: undefined,
	shouldRefreshEventMessages: false,
	userHasModifiedPriority: false,
	userHasModifiedIncludeInEventFeed: false,
	namedPoints: {},
	cities: [],
	eventIcons: {},
	routesWithRamps: [],
	ramps: {},
};
