import { css } from 'lit';
import { schema } from 'normalizr';

const signSchema = new schema.Entity('signs');
const groupSchema = new schema.Entity('groups', { signs: [signSchema] });

const userSchema = new schema.Entity('users');
const rolesSchema = new schema.Entity('roles', { users: [userSchema] });

export { signSchema, groupSchema, userSchema, rolesSchema };
