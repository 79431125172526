import { css } from 'lit';
import scss from '../../utils/scssAsCss';

export default css`
:host {
  font-size: 1.6rem;
  line-height: 2.4rem;
  --small: 1.4rem;
  --smaller: 1.0rem;
  --border-radius: 0.25rem;
}

input,
button,
select,
select > option {
  font-family: inherit;
  font-size: inherit;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

a {
  text-decoration: inherit;
  color: inherit;
}

.btn-base {
  /* normalize */
  cursor: pointer;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  background: transparent;
  border: none;
}

.btn {
  display: flex;
  align-items: center;
  flex: 0 0 content;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid rgb(var(--brand-primary));
  border-radius: 3px;
  height: 30px;
  width: auto;
  cursor: pointer;
  margin: 0 0 0 calc(var(--ggutter) / 2);
  padding: 0 var(--ggutter) 0 var(--ggutter);
  background: none;
  box-sizing: border-box;
}
.btn:first-of-type {
  margin: 0;
}
.btn img {
  flex: 0 0 content;
  pointer-events: none;
  padding-right: 10px;
}
.btn label {
  flex: 0 0 content;
}

.btn.selected {
  background-color: rgba(var(--brand-focus), 0.7);
}

.btn-lg {
  font-size: 1.4rem;
  padding: var(--ggutter) calc(var(--ggutter) * 1.5);
}

.btn-primary {
  background: rgb(var(--brand-primary));
  border: 1px solid rgb(var(--brand-primary));
  color: white;
}

.btn-danger-outline {
  border: 1px solid rgb(var(--brand-status-danger));
  color: rgb(var(--brand-status-danger));
}

.btn-good {
  border: 1px solid rgba(var(--brand-status-good), 0.5);
  background-color: rgba(var(--brand-status-good-background), 0.25);
  color: rgb(var(--brand-status-good));
}

.btn-caution {
  border: 1px solid rgba(var(--brand-status-caution), 0.5);
  background-color: rgba(var(--brand-status-caution-background), 0.25);
  color: rgb(var(--brand-status-caution));
}

.btn-danger {
  border: 1px solid rgba(var(--brand-status-danger), 0.5);
  background-color: rgba(var(--brand-status-danger-background), 0.25);
  color: rgb(var(--brand-status-danger));
}

.btn-muted {
  color: rgb(var(--brand-grey-dark));
  border: 1px solid rgb(var(--brand-grey-dimmest));
  background: rgb(var(--brand-grey-dimmest));
}

.btn-link {
  color: rgb(var(--brand-focus));
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 content;
  font-size: 1.2rem;
  font-weight: 600;
  outline: none;
}

.btn-link:hover,
.btn:hover {
  opacity: 0.7;
}

details {
  list-style: none !important;
}
details > summary {
  cursor: pointer;
  list-style: none !important;
}
details::before, details .details-marker, details > summary::-webkit-details-marker, details > summary::before {
  display: none !important;
}
details > .details-content {
  z-index: 999;
}

.text-sm {
  font-size: 1.3rem;
  line-height: 2.1rem;
}

.text-med {
  font-size: 1.5rem;
}

.text-big {
  font-size: 1.8rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label {
  font-size: 1.3rem;
  line-height: 2.1rem;
  text-transform: uppercase;
  color: rgb(var(--brand-grey));
  white-space: nowrap;
}

.link {
  color: rgb(var(--brand-focus));
  border-bottom: 1px solid rgba(var(--brand-focus), 0.15);
  margin-bottom: -1px;
}

.link:hover {
  color: rgb(var(--brand-focus-strong));
  border-bottom: 1px solid rgba(var(--brand-focus-strong), 0.15);
}

button:focus, summary:focus {
  outline: 2px solid rgb(var(--brand-focus));
}

img.closed {
  filter: grayscale(100%);
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed !important;
}

form-combo-box {
  padding: 0;
}

.badge {
  border-radius: 3px;
  display: inline-block;
  margin: calc(var(--ggutter) / -2) 0;
  padding: calc(var(--ggutter) / 8) calc(var(--ggutter) / 3);
}

.badge-good {
  border: 1px solid rgba(var(--brand-status-good), 0.5);
  background-color: rgba(var(--brand-status-good-background), 0.25);
  color: rgb(var(--brand-status-good));
}

.badge-caution {
  border: 1px solid rgba(var(--brand-status-caution), 0.5);
  background-color: rgba(var(--brand-status-caution-background), 0.25);
  color: rgb(var(--brand-status-caution));
}

.badge-danger {
  border: 1px solid rgba(var(--brand-status-danger), 0.5);
  background-color: rgba(var(--brand-status-danger-background), 0.25);
  color: rgb(var(--brand-status-danger));
}

.status {
  font-style: italic;
  color: rgb(var(--brand-grey));
}

.status-good {
  color: rgb(var(--brand-status-good));
}

.status-danger {
  color: rgb(var(--brand-status-danger));
}

.status-caution {
  color: rgb(var(--brand-status-caution));
}

textarea::placeholder,
input::placeholder {
  font-style: italic;
}

input[disabled],
button[disabled] {
  pointer-events: none;
}
`;
