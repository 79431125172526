import { css } from 'lit';
import {
	GetDMSforREMEvent,
	GetSign,
	GetSignQueue,
	RemoveDMSFromREMEvent,
	SetDMSLoadingIds,
	SetDMSforREMEvent,
	SetSign,
	SetSignQueue,
} from './rem-actions-event-dms';
import {
	AddREMEventIconToRecord,
	CreateREMEvent,
	GetREMDraftEventIcon,
	GetREMEvent,
	GetREMEventDescription,
	GetREMEventFields,
	GetREMEventFromEvents,
	GetREMEvents,
	REMEventUpdated,
	SetDraftEventProp,
	SetREMDraftEvent,
	SetREMDraftEventFutureIcon,
	SetREMDraftEventIcon,
	SetREMEvent,
	SetREMEventDescription,
	SetREMEventFields,
	SetREMEvents,
	SetREMLoading,
	SetREMReadOnlyMode,
	SetREMUserHasModifiedPriority,
	ShowREMEventSection,
	UpdateREMEvent,
} from './rem-actions-event';
import { GetREMEventCurrentEditors, SetREMEventCurrentEditors } from './rem-actions-event-editors';
import {
	HideREMEventSection,
	SetDispositionError,
	SetREMUserHasModifiedIncludeInEventFeed,
} from './rem-actions-event';
import { PrioritizeMessages } from './rem-actions-event-dms';
import { AddRampsToRecord, SetRoutesWithRamps } from './rem-actions-event-location';
import {
	SetAreas,
	SetNamedPointsForRoute,
	SetREMDraftEventRouteGeometry,
} from './rem-actions-event-location';
import {
	DeleteEmailGroups,
	GetEmailRecipients,
	SetEmailRecipients,
} from './rem-actions-event-email';
import {
	GetDraftEventRouteExtent,
	GetLocationDetails,
	GetNearbyCameras,
	GetRoutesByLocationMeta,
	ResetLocationDetails,
	SetDraftEventRouteExtent,
	SetMileMarkersValidForRoute,
	SetNearbyCameras,
	SetRoutesByLocationMeta,
	SetRoutesFilterParam,
} from './rem-actions-event-location';
import {
	AddNoteToEventTimeline,
	GetREMEventTimeline,
	SetREMEventTimeline,
} from './rem-actions-event-timeline';

export enum REMAPIAction {
	GetEvents = 'GetEvents',
	CreateEvent = 'CreateEvent',
	GetFields = 'GetFields',
}

//	ACTION TYPES

export enum REMActionType {
	GET_REM_EVENT = 'GET_REM_EVENT',
	GET_REM_EVENT_FROM_EVENTS = 'GET_REM_EVENT_FROM_EVENTS',
	CREATE_REM_EVENT = 'CREATE_REM_EVENT',
	SET_REM_EVENT = 'SET_REM_EVENT',
	UPDATE_REM_EVENT = 'UPDATE_REM_EVENT',
	REM_EVENT_UPDATED = 'REM_EVENT_UPDATED',
	GET_REM_EVENT_TIMELINE = 'GET_REM_EVENT_TIMELINE',
	SET_REM_EVENT_TIMELINE = 'SET_REM_EVENT_TIMELINE',
	ADD_NOTE_TO_EVENT_TIMELINE = 'ADD_NOTE_TO_EVENT_TIMELINE',

	GET_REM_EVENTS = 'GET_REM_EVENTS',
	SET_REM_EVENTS = 'SET_REM_EVENTS',

	GET_REM_EVENT_FIELDS = 'GET_REM_EVENT_FIELDS',
	SET_REM_EVENT_FIELDS = 'SET_REM_EVENT_FIELDS',
	GET_LOCATION_DETAILS = 'GET_LOCATION_DETAILS',
	SET_MILE_MARKERS_VALID_FOR_ROUTE = 'SET_MILE_MARKERS_VALID_FOR_ROUTE',
	GET_REM_NEARBY_CAMERAS = 'GET_REM_NEARBY_CAMERAS',
	SET_REM_NEARBY_CAMERAS = 'SET_REM_NEARBY_CAMERAS',
	GET_DMS_FOR_REM = 'GET_DEMS_PREVIEW',
	SET_DMS_PREVIEW = 'SET_DMS_PREVIEW',
	SET_DMS_PREVIEW_LOADING_IDS = 'SET_DMS_PREVIEW_LOADING_IDS',

	SET_ROUTES_FILTER_PARAM = 'SET_ROUTES_FILTER_PARAM',
	GET_ROUTES_BY_LOCATION_META = 'GET_ROUTES_BY_LOCATION_META',
	SET_ROUTES_BY_LOCATION_META = 'SET_ROUTES_BY_LOCATION_META',

	SET_REM_DRAFT_EVENT = 'SET_REM_DRAFT_EVENT',
	SET_REM_DRAFT_EVENT_PROP = 'SET_REM_DRAFT_EVENT_PROP',

	SHOW_REM_EVENT_SECTION = 'SHOW_REM_EVENT_SECTION',
	HIDE_REM_EVENT_SECTION = 'HIDE_REM_EVENT_SECTION',
	RESET_LOCATION_DETAILS = 'RESET_LOCATION_DETAILS',

	GET_REM_EVENT_EMAIL_RECIPIENTS = 'GET_REM_EVENT_EMAIL_RECIPIENTS',
	SET_REM_EVENT_EMAIL_RECIPIENTS = 'SET_REM_EVENT_EMAIL_RECIPIENTS',

	DELETE_REM_EVENT_EMAIL_GROUPS = 'DELETE_REM_EVENT_EMAIL_GROUPS',

	REMOVE_DMS_FROM_REM_EVENT = 'REMOVE_DMS_FROM_REM_EVENT',
	GET_SIGN = 'GET_SIGN',
	REM_SET_SIGN = 'REM_SET_SIGN',
	GET_SIGN_QUEUE = 'GET_SIGN_QUEUE',
	SET_SIGN_QUEUE = 'SET_SIGN_QUEUE',

	SET_REM_READ_ONLY_MODE = 'SET_REM_READ_ONLY_MODE',

	GET_REM_EVENT_CURRENT_EDITORS = 'GET_REM_EVENT_CURRENT_EDITORS',
	SET_REM_EVENT_CURRENT_EDITORS = 'SET_REM_EVENT_CURRENT_EDITORS',

	SET_REM_LOADING = 'SET_REM_LOADING',

	SET_DISPOSITION_ERROR = 'SET_DISPOSITION_ERROR',
	SET_UNSAVED_EVENT_PROP = 'SET_UNSAVED_EVENT_PROP',

	PRIORITIZE_MESSAGES = 'PRIORITIZE_MESSAGES',

	GET_REM_DRAFT_EVENT_ROUTE_EXTENT = 'GET_REM_DRAFT_EVENT_ROUTE_EXTENT',
	SET_REM_DRAFT_EVENT_ROUTE_EXTENT = 'SET_REM_DRAFT_EVENT_ROUTE_EXTENT',

	GET_REM_DRAFT_EVENT_DESCRIPTION_PREVIEW = 'GET_REM_DRAFT_EVENT_DESCRIPTION_PREVIEW',
	SET_REM_DRAFT_EVENT_DESCRIPTION_PREVIEW = 'SET_REM_DRAFT_EVENT_DESCRIPTION_PREVIEW',

	SET_REM_USE_HAS_MODIFIED_PRIORITY = 'SET_REM_USE_HAS_MODIFIED_PRIORITY',
	SET_REM_USER_HAS_MODIFIED_INCLUDE_IN_EVENT_FEED = 'SET_REM_USER_HAS_MODIFIED_INCLUDE_IN_EVENT_FEED',
	SET_REM_NAMED_POINTS_FOR_ROUTE = 'SET_REM_NAMED_POINTS_FOR_ROUTE',
	SET_REM_AREAS = 'SET_REM_AREAS',

	GET_REM_DRAFT_EVENT_ICON = 'GET_REM_DRAFT_EVENT_ICON',
	SET_REM_DRAFT_EVENT_ICON = 'SET_REM_DRAFT_EVENT_ICON',
	SET_REM_DRAFT_EVENT_FUTURE_ICON = 'SET_REM_DRAFT_EVENT_FUTURE_ICON',
	ADD_REM_EVENT_ICON_TO_RECORD = 'ADD_REM_EVENT_ICON_TO_RECORD',

	SET_REM_DRAFT_EVENT_ROUTE_GEOMETRY = 'SET_REM_DRAFT_EVENT_ROUTE_GEOMETRY',

	SET_REM_ROUTES_WITH_RAMPS = 'SET_REM_ROUTES_WITH_RAMPS',
	ADD_RAMPS_TO_RECORD = 'ADD_RAMPS_TO_RECORD',
}

export type REMAction =
	// loading
	| SetREMLoading
	//	all events
	| GetREMEvents
	| SetREMEvents
	//	single event
	| GetREMEventFromEvents
	| GetREMEvent
	| SetREMEvent
	| CreateREMEvent
	| UpdateREMEvent
	| REMEventUpdated
	| GetREMEventTimeline
	| SetREMEventTimeline
	| AddNoteToEventTimeline
	| GetREMEventCurrentEditors
	| SetREMEventCurrentEditors
	//	draft event
	| SetREMDraftEvent
	| SetDraftEventProp
	| ResetLocationDetails
	| ShowREMEventSection
	| HideREMEventSection
	| SetREMReadOnlyMode
	| SetREMUserHasModifiedPriority
	| SetREMDraftEventIcon
	| SetREMDraftEventFutureIcon
	| GetREMDraftEventIcon
	| AddREMEventIconToRecord
	| SetREMDraftEventRouteGeometry
	//	form value validation + population
	| GetREMEventFields
	| SetREMEventFields
	| GetEmailRecipients
	| SetEmailRecipients
	| DeleteEmailGroups
	| SetMileMarkersValidForRoute
	| SetDispositionError
	//	location data
	| GetLocationDetails
	| GetNearbyCameras
	| SetNearbyCameras
	| SetRoutesFilterParam
	| GetRoutesByLocationMeta
	| SetRoutesByLocationMeta
	| GetDraftEventRouteExtent
	| SetDraftEventRouteExtent
	| GetREMEventDescription
	| SetREMEventDescription
	| SetNamedPointsForRoute
	| SetAreas
	| SetRoutesWithRamps
	| AddRampsToRecord
	//	sign data
	| GetDMSforREMEvent
	| SetDMSforREMEvent
	| SetDMSLoadingIds
	| RemoveDMSFromREMEvent
	| GetSign
	| SetSign
	| GetSignQueue
	| SetSignQueue
	| PrioritizeMessages
	| SetREMUserHasModifiedIncludeInEventFeed;

export default REMAction;
