import { css } from 'lit';
/* eslint-disable max-lines */
import { Action } from 'redux';

//	ACTION TYPES

export enum CCTVActionType {
	SET_ERROR_STATE = 'SET_ERROR_STATE',
	GET_MONITORS = 'GET_MONITORS',
	SET_MONITORS = 'SET_MONITORS',
	GET_CAMERAS = 'GET_CAMERAS',
	SET_CAMERAS = 'SET_CAMERAS',
	SET_CURRENT_MONITOR_GROUP = 'SET_CURRENT_MONITOR_GROUP',
	SET_CURRENTLY_SELECTED_GRID_INDEX = 'SET_CURRENTLY_SELECTED_GRID_INDEX',
	SET_CURRENT_GRID_CONTENT = 'SET_CURRENT_GRID_CONTENT',
	START_POLLING_MONITORS = 'START_POLLING_MONITORS',
	STOP_POLLING_MONITORS = 'STOP_POLLING_MONITORS',
	START_POLLING_CAMERAS = 'START_POLLING_CAMERAS',
	STOP_POLLING_CAMERAS = 'STOP_POLLING_CAMERAS',
	SET_MOBILE_STATE = 'SET_MOBILE_STATE',
	SAVE_DEVICE = 'SAVE_DEVICE',
	SET_CCTV_LOADING = 'SET_CCTV_LOADING',
	SET_CAMERA_TYPES = 'SET_CAMERA_TYPES',
	SET_CAMERA = 'SET_CAMERA',
	GET_CAMERA_MODELS = 'GET_CAMERA_MODELS',
	SET_CAMERA_MODELS = 'SET_CAMERA_MODELS',
	GET_CAMERA_ROLES = 'GET_CAMERA_ROLES',
	SET_CAMERA_ROLES = 'SET_CAMERA_ROLES',
	GET_EXISTING_DEVICE = 'GET_EXISTING_DEVICE',
	SET_EXISTING_DEVICE = 'SET_EXISTING_DEVICE',
}

interface SetCctvLoading extends Action<CCTVActionType.SET_CCTV_LOADING> {
	loading?: boolean;
}

export const setCctvLoading = (loading?: boolean): SetCctvLoading => ({
	type: CCTVActionType.SET_CCTV_LOADING,
	loading,
});
