import { css } from 'lit';
import { CustomMessageDto, DMSSignType } from '../../typings/api';
import ConfigCCTV from './ConfigCCTV';

import {
	MILLISECONDS_PER_MINUTE,
	MILLISECONDS_PER_SECOND,
	MessageTypes,
	DMS_IMAGE_COLUMNS,
} from '../constants';
import { DmsSignTypes, CUSTOM_MESSAGE_COLUMNS, DMS_COLUMNS, DMS_GROUP_COLUMNS } from '../constants';

interface ConfigDMS {
	Map: {
		DefaultLat: number;
		DefaultLon: number;
		DefaultZoom: number;
		MinZoom: number;
		MaxZoom: number;
		SpiderifyDistancePx: number;
		SpiderifyLegThicknessPx: number;
		SpiderifyLegColorNormal: string;
		SpiderifyLegColorFocus: string;
		icons: {
			[key in keyof typeof DmsSignTypes]: {
				message: string;
				noMessage: string;
				offline: string;
				selected?: string;
			};
		};
	};
	Table: {
		Pagination: number[];
	};
	location: Record<string, string>;
}

export const ConfigDMS: ConfigDMS = {
	Map: {
		//  Leaflet map will center on this point, at the provided zoom level
		//  https://leafletjs.com/reference-1.4.0.html#map-setview
		DefaultLat: ConfigCCTV.Map.DefaultLat,
		DefaultLon: ConfigCCTV.Map.DefaultLon,
		DefaultZoom: ConfigCCTV.Map.DefaultZoom,
		MinZoom: ConfigCCTV.Map.MinZoom,
		MaxZoom: ConfigCCTV.Map.MaxZoom,
		SpiderifyDistancePx: ConfigCCTV.Map.SpiderifyDistancePx,
		SpiderifyLegThicknessPx: ConfigCCTV.Map.SpiderifyLegThicknessPx,
		SpiderifyLegColorNormal: ConfigCCTV.Map.SpiderifyLegColorNormal,
		SpiderifyLegColorFocus: ConfigCCTV.Map.SpiderifyLegColorFocus,
		icons: {
			TTS: {
				message: 'img/icon-travel_time-fill-solid-1.svg',
				noMessage: 'img/icon-travel_time-no_message-fill-solid-1.svg',
				offline: 'img/icon-full-travel-time-no_data-fill-solid-1.svg',
			},
			DMS: {
				message: 'img/icon-dms-fill-solid.svg',
				noMessage: 'img/icon-dms-no_message-fill-solid.svg',
				offline: 'img/icon-dms-no_data-fill-solid.svg',
				selected: 'img/icon-dms-selected.svg',
			},
			TPIMS: {
				message: 'img/icon-travel_time-fill-solid.svg',
				noMessage: 'img/icon-travel_time-no_message-fill-solid.svg',
				offline: 'img/icon-full-color-sign-no_data-fill-solid.svg',
			},
			VSL: {
				message: 'img/icon-speed-fill-solid.svg',
				noMessage: 'img/icon-speed-no_message-fill-solid.svg',
				offline: 'img/icon-speed-no_data-fill-solid.svg',
			},
			PDMS: {
				message: 'img/icon-portable-fill-solid.svg',
				noMessage: 'img/icon-portable-no_message-fill-solid.svg',
				offline: 'img/icon-portable-no_data-fill-solid.svg',
				selected: 'img/icon-portable-selected.svg',
			},
		},
	},
	Table: {
		Pagination: [5, 15, 50, 100],
	},
	location: {
		/*
			<input pattern="" /> regex is slightly different than javascript
			equivalent pattern in javascript: /^(?:\d{1,3}(?:\.\d{1})?)$/gm
		*/
		mileMarkerPattern: '[0-9]{1,3}(?:\\.[0-9]{1})?',
		mileMarkerInvalidPattern: 'Value must be formatted "mmm.t"',
		mileMarkerValueFitted: 'Mile marker entry snapped to nearest valid value.',
		mileMarkerEndValueInvalid: 'Invalid end value removed.',
		mileMarkerMissingRoute: 'Cannot validate mile markers without route',
	},
};

/* eslint-disable camelcase */
export const ConfigDMSApi = {
	SignsEndpoint: 'sign', //  GET
	SignGraphics: 'sign/graphic', //  GET
	GroupsEndpoint: 'sign/groups',
	RoutesEndpoint: 'locations/route',
	SaveSignEndpoint: 'sign/save/sign',
	SaveGroupEndpoint: 'sign/save/group',
	UpdateSignMessageTimes: 'sign/update/messages/start-end-time',
	DeleteGroupEndpoint: (groupIds: number[]): string => `sign/delete/groups/${groupIds.join(',')}`,
	UpdateGroupEndpoint: (groupId: number): string => `sign/update/group/${groupId}`,
	locationDetails: (route_designator: string): string =>
		`locations/route/${encodeURIComponent(route_designator)}`,
	signQueueById: (signId: number): string => `sign/queue/${signId}`,
	promoteMessageByIds: (signId: number, messageId: number): string =>
		`sign/promote/${signId}/${messageId}`,
	customMessageById: (messageId: number): string => `sign/messages/custom/${messageId}`,
	deleteCustomMessagesByIds: (messageIds: number[]): string =>
		`sign/delete/customs/${messageIds.join(',')}`,
	routeExtent: (route: string, startMileMarker: number, endMileMarker: number): string =>
		`sign/route-extent/${encodeURIComponent(route)}/${startMileMarker}/${endMileMarker}`,
	deleteSigns: (signIds: number[]): string => `sign/delete/signs/${signIds.join(',')}`,
	CustomMessagesEndpoint: 'sign/messages/custom',
	CustomMessagesPostEndpoint: 'sign/save/custom',
	signSaveGraphic: 'sign/save/graphic',
	signDeleteGraphic: (graphicId: number): string => `sign/delete/graphic/${graphicId}`,
	addCustomMessageToSigns: (customMessageId: number, signIds: number[]): string =>
		`sign/queue/custom-message/${customMessageId}/${signIds.join(',')}`,
	deleteMessageFromQueueEndpoint: (messageId: number, allMessages: boolean): string =>
		`sign/delete/messages/queues/${messageId}/${allMessages ? 'true' : 'false'}`,
	signById: (signId: number): string => `sign/id/${signId}`,
	currentSignMessage: (signId: number): string => `sign/id/${signId}/messages/current`,
	signFields: 'sign/fields',
	endpointTimeoutMs: 60 * MILLISECONDS_PER_SECOND,
	signsPollingRate: 60 * MILLISECONDS_PER_SECOND,
	signQueuePollingRate: 10 * MILLISECONDS_PER_SECOND,
	dateFormat: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
	dateOptions: {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		timeZoneName: 'short',
	},
};
/* eslint-enable camelcase */

export const ConfigDMSTable = {
	pagination: [5, 15, 50, 100],
	defaultPagination: 3,
	defaultColumns: [
		'number',
		'name',
		'status',
		'queue',
		'route',
		'mileMarker',
		'direction',
		'group',
		'type',
	] as typeof DMS_COLUMNS[number][],
};

export const ConfigDMSGraphicTable = {
	pagination: [5, 15, 50, 100],
	defaultPagination: 3,
	defaultColumns: ['preview', 'title', 'uploaded', 'created'] as typeof DMS_IMAGE_COLUMNS[number][],
};

export const ConfigDMSCustomMessagesTable = {
	pagination: [5, 15, 50, 100],
	defaultPagination: 3,
	defaultColumns: [
		'frame1',
		'type',
		'created',
		'lastUsed',
		'createdBy',
		'permanent',
	] as typeof CUSTOM_MESSAGE_COLUMNS[number][],
};

export const ConfigDMSGroupsTable = {
	pagination: [5, 15, 50, 100],
	defaultPagination: 3,
	defaultColumns: ['name', 'signs'] as typeof DMS_GROUP_COLUMNS[number][],
};

export const MessageIcons: Record<Exclude<MessageTypes, MessageTypes.EVENT>, string> = {
	[MessageTypes.CUSTOM]: 'img/icon-custom-message.svg',
	[MessageTypes.TTS]: 'img/icon-travel_time-fill-solid-1.svg',
	[MessageTypes.TPIMS]: 'img/icon-travel_time-fill-solid.svg',
	[MessageTypes.CONGESTION]: 'img/icon-custom-message.svg',
};

export const locationInfoToKey = (route: string, start: number, end?: number): string => {
	if (end) {
		return `${route}s?${start};e?${end}`;
	}

	return `${route}s?${start};`;
};

export interface DMSSelectionUpdate {
	message: CustomMessageDto;
	start: number;
	end: number;
	priority: number;
	groupId: number;
}

export const graphicSignTypes: DMSSignType[] = [DMSSignType.DMS, DMSSignType.PDMS];

export const MINIMUM_MESSAGE_DURATION = 1 * MILLISECONDS_PER_MINUTE;

export const MINIMUM_AOI_LENGTH = 0.1;
