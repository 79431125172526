import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import {
	ChangeSetItem,
	HighwayHelperDto,
	HighwayHelperTimelineDto,
	MetricsEventsSearchParams,
	MetricsEventsSearchResults,
	RoadEventDto,
	RoadEventTimelineDto,
	TimelineEntry,
} from '../../../typings/api';
import {
	NotificationErrorType,
	TimelineDay,
	TimelineDayEntryChange,
} from '../../../typings/shared-types';
import ConfigCARSx, { APIConfig, DebuggingConfig } from '../../config/ConfigCARSx';
import { ConfigMetricsApi } from '../../config/ConfigMetrics';
import { ConfigREMEventForm } from '../../config/ConfigREM';
import { TimelineSortOrder } from '../../config/ConfigREMTimeline';
import { LOCALE } from '../../constants';
import formatTimezoneSup from '../../utils/format-timezone-sup';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { selectChangeSetItemToTimelineDayEntryChange } from '../hh/hh-selectors';
import { navigate } from '../redux-routing';
import { RootState, ThunkActionRoot } from '../redux-store';
import { showMainBanner } from '../redux-ui';

// STATE

export type MetricsState = {
	events?: RoadEventDto[];
	helpers?: HighwayHelperDto[];
	hhStart: number;
	hhEnd: number;
	helper?: HighwayHelperDto;
	helperTimeline?: HighwayHelperTimelineDto;
	event?: RoadEventDto;
	eventTimeline?: RoadEventTimelineDto;
	eventsTotalRecordCount: number;
	eventsSearchParams?: MetricsEventsSearchParams;
};
export default MetricsState;

export const METRICS_STATE_INITIAL: MetricsState = {
	events: undefined,
	helpers: undefined,
	hhStart: NaN,
	hhEnd: NaN,
	helper: undefined,
	helperTimeline: undefined,
	event: undefined,
	eventTimeline: undefined,
	eventsTotalRecordCount: NaN,
	eventsSearchParams: undefined,
};

// ACTION TYPES

export enum MetricsActionType {
	SET_METRICS_EVENTS_ERROR_STATE = 'SET_METRICS_EVENTS_ERROR_STATE',

	GET_METRICS_EVENTS = 'GET_METRICS_EVENTS',
	SET_METRICS_EVENTS = 'SET_METRICS_EVENTS',
	SET_METRICS_EVENTS_TOTAL_RECORD_COUNT = 'SET_METRICS_EVENTS_TOTAL_RECORD_COUNT',

	SET_METRICS_HELPERS_ERROR_STATE = 'SET_METRICS_HELPERS_ERROR_STATE',

	GET_METRICS_HELPERS = 'GET_METRICS_HELPERS',
	SET_METRICS_HELPERS = 'SET_METRICS_HELPERS',

	SET_METRICS_HH_START = 'SET_METRICS_HH_START',
	SET_METRICS_HH_END = 'SET_METRICS_HH_END',

	GET_METRICS_HELPER = 'GET_METRICS_HELPER',
	SET_METRICS_HELPER = 'SET_METRICS_HELPER',

	GET_METRICS_HH_TIMELINE = 'GET_METRICS_HH_TIMELINE',
	SET_METRICS_HH_TIMELINE = 'SET_METRICS_HH_TIMELINE',

	GET_METRICS_EVENT = 'GET_METRICS_EVENT',
	SET_METRICS_EVENT = 'SET_METRICS_EVENT',

	GET_METRICS_EVENT_TIMELINE = 'GET_METRICS_EVENT_TIMELINE',
	SET_METRICS_EVENT_TIMELINE = 'SET_METRICS_EVENT_TIMELINE',

	SET_METRICS_EVENTS_SEARCH_PARAMS = 'SET_METRICS_EVENTS_SEARCH_PARAMS',
}

interface SetMetricsEvents extends Action<typeof MetricsActionType.SET_METRICS_EVENTS> {
	events: RoadEventDto[];
}

interface SetMetricsEventsTotalRecordCount
	extends Action<typeof MetricsActionType.SET_METRICS_EVENTS_TOTAL_RECORD_COUNT> {
	totalRecordCount: number;
}

interface SetMetricsHelpers extends Action<typeof MetricsActionType.SET_METRICS_HELPERS> {
	helpers: HighwayHelperDto[];
}

interface SetMetricsHHStart extends Action<typeof MetricsActionType.SET_METRICS_HH_START> {
	start: number;
}

interface SetMetricsHHEnd extends Action<typeof MetricsActionType.SET_METRICS_HH_END> {
	end: number;
}

interface SetMetricsHelper extends Action<typeof MetricsActionType.SET_METRICS_HELPER> {
	helper: HighwayHelperDto;
}

interface SetMetricsHHTimeline extends Action<typeof MetricsActionType.SET_METRICS_HH_TIMELINE> {
	timeline: HighwayHelperTimelineDto;
}

interface SetMetricsEvent extends Action<typeof MetricsActionType.SET_METRICS_EVENT> {
	event: RoadEventDto;
}

interface SetMetricsEventTimeline
	extends Action<typeof MetricsActionType.SET_METRICS_EVENT_TIMELINE> {
	timeline: RoadEventTimelineDto;
}

interface SetMetricsEventsSearchParams
	extends Action<typeof MetricsActionType.SET_METRICS_EVENTS_SEARCH_PARAMS> {
	params: MetricsEventsSearchParams;
}

export type MetricsAction =
	| SetMetricsEvents
	| SetMetricsHelpers
	| SetMetricsHHStart
	| SetMetricsHHEnd
	| SetMetricsHelper
	| SetMetricsHHTimeline
	| SetMetricsEvent
	| SetMetricsEventTimeline
	| SetMetricsEventsTotalRecordCount
	| SetMetricsEventsSearchParams;

// ACTIONS

export const setMetricsHelper = (helper: HighwayHelperDto): SetMetricsHelper => ({
	type: MetricsActionType.SET_METRICS_HELPER,
	helper,
});

export const setMetricsEvent = (event: RoadEventDto): SetMetricsEvent => ({
	type: MetricsActionType.SET_METRICS_EVENT,
	event,
});

export const setMetricsEventTimeline = (
	timeline: RoadEventTimelineDto,
): SetMetricsEventTimeline => ({
	type: MetricsActionType.SET_METRICS_EVENT_TIMELINE,
	timeline,
});

export const setMetricsEventsSearchParams = (
	params: MetricsEventsSearchParams,
): SetMetricsEventsSearchParams => ({
	type: MetricsActionType.SET_METRICS_EVENTS_SEARCH_PARAMS,
	params,
});

export const getMetricsEvents =
	(params: MetricsEventsSearchParams): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: MetricsActionType.GET_METRICS_EVENTS });

		const url = new URL(ConfigMetricsApi.events, APIConfig.endpointURLBase);

		Object.keys(params).forEach((paramName) => {
			const key = paramName as keyof MetricsEventsSearchParams;
			const paramValue = params[key];

			if (Array.isArray(paramValue) && paramValue.length) {
				paramValue.forEach((value) => url.searchParams.append(paramName, value.toString()));
			} else if (paramValue !== undefined && !Number.isNaN(paramValue)) {
				url.searchParams.append(paramName, paramValue.toString());
			}
		});

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigMetricsApi.endpointTimeoutMs,
		);

		if (!apiRequestReturn?.response) {
			dispatch({
				type: MetricsActionType.SET_METRICS_EVENTS_ERROR_STATE,
				apiError: APIError.FetchFailed,
			});
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages.login.route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const results = (await apiRequestReturn.response
					.clone()
					.json()) as MetricsEventsSearchResults;
				const events = results.events as RoadEventDto[];
				const { totalRecordCount } = results;

				dispatch({ type: MetricsActionType.SET_METRICS_EVENTS, events });
				dispatch({
					type: MetricsActionType.SET_METRICS_EVENTS_TOTAL_RECORD_COUNT,
					totalRecordCount: totalRecordCount ?? NaN,
				});
			} catch (error) {
				dispatch({
					type: MetricsActionType.SET_METRICS_EVENTS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({
				type: MetricsActionType.SET_METRICS_EVENTS_ERROR_STATE,
				apiError: APIError.FetchFailed,
			});
		}
	};

export const getMetricsHelpers =
	(startTime: number, endTime: number, names: string[]): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: MetricsActionType.GET_METRICS_EVENTS });

		const url = new URL(ConfigMetricsApi.helpers, APIConfig.endpointURLBase);
		url.searchParams.append('start-time', startTime.toString());
		url.searchParams.append('end-time', endTime.toString());
		if (names.length > 0) {
			url.searchParams.append('names', names.toString());
		}

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigMetricsApi.endpointTimeoutMs,
		);

		if (!apiRequestReturn?.response) {
			dispatch({
				type: MetricsActionType.SET_METRICS_HELPERS_ERROR_STATE,
				apiError: APIError.FetchFailed,
			});
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages.login.route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const helpers = (await apiRequestReturn.response.clone().json()) as HighwayHelperDto[];
				dispatch({ type: MetricsActionType.SET_METRICS_HELPERS, helpers });
			} catch (error) {
				dispatch({
					type: MetricsActionType.SET_METRICS_HELPERS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({
				type: MetricsActionType.SET_METRICS_HELPERS_ERROR_STATE,
				apiError: APIError.FetchFailed,
			});
		}
	};

export const getMetricsHHTimeline =
	(id: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		const apiRequestReturn: APIRequestReturn = await APIRequest(
			new Request(new URL(ConfigMetricsApi.helperTimeline(id), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		try {
			const timeline = (await apiRequestReturn.response
				?.clone()
				?.json()) as HighwayHelperTimelineDto;
			dispatch({ type: MetricsActionType.SET_METRICS_HH_TIMELINE, timeline });
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing event timeline for event #${id}:`, error);
			}
		}

		return apiRequestReturn;
	};

export const getMetricsEvent =
	(id: number): ThunkActionRoot<Promise<RoadEventDto | undefined>> =>
	async (dispatch): Promise<RoadEventDto | undefined> => {
		dispatch({
			type: MetricsActionType.GET_METRICS_EVENT,
			id,
		});
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigMetricsApi.event(id), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);
		let event: RoadEventDto | undefined;

		if (!apiRequestReturn.response?.ok) {
			dispatch(
				showMainBanner(NotificationErrorType.ERROR, {
					title: `Failed to fetch metrics event with ID: ${id}`,
				}),
			);
			return event;
		}

		try {
			event = (await apiRequestReturn.response?.clone().json()) as RoadEventDto;
			dispatch(setMetricsEvent(event));
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing event #${id}:`, error);
			}
		}

		return event;
	};

export const getMetricsHelper =
	(id: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: MetricsActionType.GET_METRICS_HELPER,
			id,
		});
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigMetricsApi.helper(id), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		try {
			const helper: HighwayHelperDto = (await apiRequestReturn.response
				?.clone()
				.json()) as HighwayHelperDto;
			dispatch(setMetricsHelper(helper));
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing helper #${id}:`, error);
			}
		}
		return apiRequestReturn;
	};

export const getMetricsEventTimeline =
	(id: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({
			type: MetricsActionType.GET_METRICS_EVENT_TIMELINE,
			id,
		});
		const apiRequestReturn = await APIRequest(
			new Request(new URL(ConfigMetricsApi.eventTimeline(id), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
		);

		try {
			const timeline: RoadEventTimelineDto = (await apiRequestReturn.response
				?.clone()
				.json()) as RoadEventTimelineDto;
			dispatch(setMetricsEventTimeline(timeline));
		} catch (error) {
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`error parsing event #${id}:`, error);
			}
		}
		return apiRequestReturn;
	};
// REDUCER

export const MetricsReducer = (
	state: MetricsState = METRICS_STATE_INITIAL,
	action: MetricsAction | undefined = undefined,
): MetricsState => {
	if (action === undefined) {
		return state;
	}
	switch (action.type) {
		case MetricsActionType.SET_METRICS_EVENTS:
			return {
				...state,
				events: action.events,
			};
		case MetricsActionType.SET_METRICS_HELPERS:
			return {
				...state,
				helpers: action.helpers,
			};
		case MetricsActionType.SET_METRICS_HH_START:
			return {
				...state,
				hhStart: action.start,
			};
		case MetricsActionType.SET_METRICS_HH_END:
			return {
				...state,
				hhEnd: action.end,
			};
		case MetricsActionType.SET_METRICS_HELPER:
			return {
				...state,
				helper: action.helper,
			};
		case MetricsActionType.SET_METRICS_HH_TIMELINE:
			return {
				...state,
				helperTimeline: action.timeline,
			};
		case MetricsActionType.SET_METRICS_EVENT:
			return {
				...state,
				event: action.event,
			};
		case MetricsActionType.SET_METRICS_EVENT_TIMELINE:
			return {
				...state,
				eventTimeline: action.timeline,
			};
		case MetricsActionType.SET_METRICS_EVENTS_TOTAL_RECORD_COUNT:
			return {
				...state,
				eventsTotalRecordCount: action.totalRecordCount,
			};
		case MetricsActionType.SET_METRICS_EVENTS_SEARCH_PARAMS:
			return {
				...state,
				eventsSearchParams: action.params,
			};
		default:
			return state;
	}
};

// SELECTORS

export const selectMetricsHelperById = (
	state: RootState,
	id: number,
): HighwayHelperDto | undefined => state.metrics?.helpers?.find((helper) => helper.id === id);

export const selectMetricsHHTimelineByDay = (
	state: RootState,
	start: number,
	end: number,
): TimelineDay[] =>
	(state.metrics?.helperTimeline?.timeline?.entries as TimelineEntry[])?.reduce(
		(timelineDays: TimelineDay[], timelineEntry: TimelineEntry): TimelineDay[] => {
			//	is there already a container for the day this change occurred on?
			const timestamp = timelineEntry.timestamp ?? NaN;
			const date = new Date(timestamp).getTime();
			if (date < start || date > end) {
				return timelineDays;
			}

			const dateString = new Date(timestamp).toLocaleDateString(
				LOCALE,
				ConfigREMEventForm.timelineDateFormatOptions,
			);
			const lastTimelineDay = timelineDays[timelineDays.length - 1];
			let timelineDay: TimelineDay;
			if (lastTimelineDay?.dateString === dateString) {
				timelineDay = lastTimelineDay;
			} else {
				timelineDay = {
					dateString,
					entries: [],
				};
				timelineDays.push(timelineDay);
			}
			//	what changes occured on this day?
			const timeString = formatTimezoneSup(
				new Date(timestamp).toLocaleTimeString(
					LOCALE,
					ConfigREMEventForm.timelineTimeFormatOptions,
				),
			);
			const user = timelineEntry.changeSets?.[0].changeSetItems?.[0]
				? timelineEntry.changeSets?.[0].changeSetItems?.[0].createdBy
				: null;
			const changes: TimelineDayEntryChange[] = [];
			timelineEntry.changeSets?.forEach((changeSet) => {
				changeSet.changeSetItems?.forEach((changeSetItem: ChangeSetItem) => {
					const timelineDayEntryChange = selectChangeSetItemToTimelineDayEntryChange(
						changeSet.name as string,
						changeSetItem,
					);
					if (timelineDayEntryChange !== undefined) {
						changes.push(timelineDayEntryChange);
					}
				});
			});

			if (changes.length === 0) {
				if (DebuggingConfig.showConsoleLogs) {
					console.warn(
						`no ChangeSetItems could be resolved to TimelineDayEntryChanges for "${dateString} ${timeString}"`,
					);
				}
			}

			changes.sort((a, b) => {
				return TimelineSortOrder.indexOf(a.subject) - TimelineSortOrder.indexOf(b.subject);
			});

			if (user && changes.length) {
				timelineDay.entries.push({
					timeString,
					user,
					changes,
				});
			}

			return timelineDays;
		},
		[] as TimelineDay[],
	);
