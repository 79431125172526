import { css } from 'lit';
/**
 * Add <sup> tags around timezone string
 *
 * @param {string}dateString formatted date ending with a timeznne part
 * {string} @returns html
 */
export function formatTimezoneSup(dateString: string): string {
	const parts = dateString.split(' ');
	parts[parts.length - 1] = `<sup>${parts[parts.length - 1]}</sup>`;
	return parts.join(' ');
}

export default formatTimezoneSup;
