import { css } from 'lit';
/**
 * @param {string}href  fully formed url to navigate to
 * @param {string}target window behavior
 */
function open(href: string, target = '_blank'): void {
	const newTab = window.open('', target);
	//	prevent the page in the new window from accessing the window that opened it.
	if (newTab !== null) {
		newTab.opener = null;
		newTab.location.replace(href);
	} else {
		// TODO: what does a null reference to newTab imply?
	}
}

/**
 * @param {Function} locationUpdatedCallback callback to be called when the location is updated
 * @returns {Promise} (unused)
 */
export function installRouter(
	locationUpdatedCallback: (href: string, firstRun: boolean) => Promise<void> | void,
): void {
	document.body.addEventListener('click', (e) => {
		if (e.defaultPrevented || e.button !== 0 || e.metaKey || e.ctrlKey || e.shiftKey) {
			return;
		}

		const anchor = e
			.composedPath()
			.filter((n) => (n as HTMLElement).tagName === 'A')[0] as HTMLAnchorElement;
		if (!anchor || anchor.hasAttribute('download')) {
			return;
		}

		/**
		 * Use href string as in the html so we can capture determine if a link starts
		 * with a # for routing to modals without affecting the tray
		 */
		const hrefUnprocessed = anchor.getAttribute('href');
		const { href } = anchor;
		// eslint-disable-next-line no-script-url
		if (!href || !hrefUnprocessed || href.includes('mailto:') || href.includes('javascript:')) {
			return;
		}

		e.preventDefault();

		const { location } = window;
		const origin = location.origin || `${location.protocol}//${location.host}`;

		if (!href.startsWith(origin) || anchor.target || anchor.getAttribute('rel') === 'external') {
			open(anchor.href, anchor.target || '_blank');
			return;
		}

		const normHref = new URL(!href.startsWith(origin) ? `${origin}${href}` : href);
		if (!normHref.href.startsWith(document.baseURI)) {
			open(anchor.href, anchor.target || '_self');
			return;
		}

		void locationUpdatedCallback(hrefUnprocessed, false);
	});

	window.addEventListener('popstate', () => {
		void locationUpdatedCallback(window.location.href, false);
	});
	void locationUpdatedCallback(window.location.href, true);
}
export default installRouter;
