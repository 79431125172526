import { css } from 'lit';
const EDITOR_UUID = 'session-editor-uuid';

const DUPLICATE_TAB_NAVIGATION_TYPE = 'back_forward';

/**
 * @returns {string} - UUID for current user session
 */
export function getSessionUUID(): string {
	let editorUUid = window.sessionStorage.getItem(EDITOR_UUID);
	if (!editorUUid) {
		editorUUid = window.crypto.randomUUID();
		window.sessionStorage.setItem(EDITOR_UUID, editorUUid);
	}
	return editorUUid;
}

/**
 * Initializes session for uuid by checking for tab duplication
 */
export function initSessionUUID(): void {
	const navigationType = window.performance.getEntriesByType('navigation')[0].entryType;

	if (navigationType === DUPLICATE_TAB_NAVIGATION_TYPE) {
		window.sessionStorage.clear();
	}
}
