import { css } from 'lit';
import 'overlapping-marker-spiderfier-leaflet/dist/oms';
import { customElement, property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { CSSResultArray, LitElement, PropertyValues, TemplateResult, html } from 'lit';
import { connect } from 'pwa-helpers';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { initSessionUUID } from '@utils/SessionStorageUtils';
import store, { RootState } from '../redux/redux-store';
import CSSReset from './css/lib/reset.scss';
import SharedStyles from './css/shared.scss';
import scss from '../utils/scssAsCss';
import { navigate } from '../redux/redux-routing';
import installRouter from '../utils/install-router';
import './common/page-nav';
import { selectBannersActive } from '../redux/redux-ui';
import { AppSection } from '../constants';
import { BannerLocation } from '../../typings/shared-types';
import { setOnline } from '../redux/redux-network';
import './common/loading-page';
import { APIConfig } from '../config/ConfigCARSx';
import '../utils/random-uuid-polyfill';
import Authorization from '../rest/Authorization';

@customElement('carsx-app')
export default class App extends connect(store)(LitElement) {
	@state() private page?: AppSection;

	@state() private loadingRouteProgress = 0;

	@state() private isBodyNoScroll = false;

	@state() private isLoadingRoute = false;

	@state() private isBannerActive = false;

	@property({ type: Boolean, reflect: true }) public shownav = false;

	public static override get styles(): CSSResultArray {
		return [
			CSSReset,
			SharedStyles,
			css`
:host {
  width: 100%;
  display: flex;
  flex-direction: column;
}

:host([shownav=true]) {
  background-color: #fff;
}

.loading-route {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  z-index: 3000;
  background-color: rgb(var(--brand-secondary));
  transform-origin: left;
  -webkit-transform: scaleX(0);
  transform: scaleX(1);
  transition-property: transform;
  transition-duration: 0.25s;
  transition-timing-function: ease;
}

/* .banner-active {
	top: var(--topbar-height); 
} */
`,
		];
	}

	public override render(): TemplateResult {
		this.shownav = this.page !== AppSection.LOGIN && Authorization.JWT !== null;
		return html`
			<div
				class="loading-route"
				?hidden=${this.loadingRouteProgress === 0}
				style=${styleMap({
					transform: `scaleX(${this.loadingRouteProgress})`,
				})}
			></div>

			${this.page === AppSection.LOGIN ? html`<carsx-login></carsx-login> ` : undefined}
			${this.shownav ? html`<page-nav page=${ifDefined(this.page)}></page-nav>` : undefined}
			${this.page === undefined && this.isLoadingRoute && this.loadingRouteProgress > 0.5
				? html`<loading-page></loading-page>`
				: undefined}
			${this.page === AppSection.CCTV ? html` <carsx-cctv></carsx-cctv> ` : undefined}
			${this.page === AppSection.DMS
				? html`
						<carsx-dms class=${classMap({ 'banner-active': this.isBannerActive })}></carsx-dms>
				  `
				: undefined}
			${this.page === AppSection.REM
				? html`
						<carsx-rem class=${classMap({ 'banner-active': this.isBannerActive })}></carsx-rem>
				  `
				: undefined}
			${this.page === AppSection.HH
				? html` <carsx-hh class=${classMap({ 'banner-active': this.isBannerActive })}></carsx-hh> `
				: undefined}
			${this.page === AppSection.METRICS
				? html`
						<carsx-metrics
							class=${classMap({ 'banner-active': this.isBannerActive })}
						></carsx-metrics>
				  `
				: undefined}
			${this.page === AppSection.USER_GROUPS
				? html`
						<user-groups class=${classMap({ 'banner-active': this.isBannerActive })}></user-groups>
				  `
				: undefined}
		`;
	}

	protected override firstUpdated(): void {
		installRouter((href) => {
			const path = window.decodeURIComponent(href);
			void store.dispatch(navigate(path));
		});
	}

	protected override updated(changed: PropertyValues): void {
		if (changed.has('isBodyNoScroll') && this.isBodyNoScroll) {
			document.body.classList.add('noScroll');
		} else if (changed.has('isBodyNoScroll')) {
			document.body.classList.remove('noScroll');
		}

		if (changed.has('isLoadingRoute') && this.isLoadingRoute) {
			this.startRouteLoading();
		} else if (changed.has('isLoadingRoute')) {
			this.endRouteLoading();
		}
	}

	public override stateChanged(state: RootState): void {
		this.page = state.routing.page ?? undefined; //	is there any significance to null versus undefined for this prop?
		this.isBodyNoScroll = state.ui.isBodyNoScroll;
		this.isBannerActive = selectBannersActive(state, BannerLocation.MAIN);

		this.isLoadingRoute = state.ui.isLoadingRoute;
	}

	private routeLoaderInterval?: ReturnType<typeof setTimeout>;

	private startRouteLoading(): void {
		const INTERVAL = 50;
		const INITIAL_THRESHOLD = 0.5;
		const INITIAL_INCREMENT = 0.05;

		if (this.routeLoaderInterval !== undefined) {
			clearInterval(this.routeLoaderInterval);
		}
		this.routeLoaderInterval = setInterval(() => {
			// increment load progress linearly until some threshold
			if (this.loadingRouteProgress < INITIAL_THRESHOLD) {
				this.loadingRouteProgress += INITIAL_INCREMENT;
			} else {
				// then increment slowly so progress bar will never be full until a request would timeout
				this.loadingRouteProgress +=
					(INTERVAL * (1 - INITIAL_THRESHOLD)) /
					(APIConfig.requestTimeoutMs - (INTERVAL * INITIAL_THRESHOLD) / INITIAL_INCREMENT);
			}
		}, INTERVAL);
	}

	private endRouteLoading(): void {
		if (this.routeLoaderInterval !== undefined) {
			clearInterval(this.routeLoaderInterval);
		}
		if (this.loadingRouteProgress > 0.1) {
			this.loadingRouteProgress = 1;
		}
		setTimeout(() => {
			this.loadingRouteProgress = 0;
		}, 500);
	}

	private onWindowOffline(): void {
		store.dispatch(setOnline(false));
	}

	private onWindowOnline(): void {
		store.dispatch(setOnline(true));
	}

	public override connectedCallback(): void {
		super.connectedCallback();

		window.addEventListener('offline', this.onWindowOffline);
		window.addEventListener('online', this.onWindowOnline);

		initSessionUUID();
	}

	public override disconnectedCallback(): void {
		super.disconnectedCallback();

		window.removeEventListener('offline', this.onWindowOffline);
		window.removeEventListener('online', this.onWindowOnline);
	}
}
