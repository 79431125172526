import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { normalize } from 'normalizr';
import { Action } from 'redux';
import { CreateUpdateGroupDto, GroupDto, SignDto } from '../../../typings/api';
import { NotificationErrorType } from '../../../typings/shared-types';
import { ConfigDMSApi } from '../../config/ConfigDMS';
import ConfigCARSx, { APIConfig } from '../../config/ConfigCARSx';
import { AppSection, SignsLoaderState } from '../../constants';
import { groupSchema } from '../../schemas';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { addItem, removeItem } from '../redux-loaderAnim';
import { navigate } from '../redux-routing';
import { ThunkActionRoot } from '../redux-store';
import { showMainBanner } from '../redux-ui';
import { DMSActionType } from './dms-actions';
import { addCustomMessageToSigns } from './dms-actions-sign';
import { groupsSelector } from './dms-selectors';
import { DmsEntities } from './dms-state';

export interface SetGroupsErrorState extends Action<typeof DMSActionType.SET_GROUPS_ERROR_STATE> {
	apiError?: APIError;
}

export type GetGroups = Action<DMSActionType.GET_GROUPS>;

export interface SetGroups extends Action<DMSActionType.SET_GROUPS> {
	groupIds?: number[];
	entities?: DmsEntities;
}

export interface DeleteGroups extends Action<DMSActionType.DELETE_GROUPS> {
	groupIds: number[];
}

export interface SaveGroup extends Action<typeof DMSActionType.SAVE_GROUP> {
	groupId: number;
	entities?: DmsEntities;
}

export interface SaveSignToGroups extends Action<typeof DMSActionType.SAVE_SIGN_TO_GROUPS> {
	sign: SignDto;
	groups: GroupDto[];
}

export interface AddCustomMessageToGroup extends Action<DMSActionType.ADD_CUSTOM_MESSAGE_TO_GROUP> {
	customMessageId: number;
	start: number;
	end: number;
	groupId: number;
}

export const getGroups =
	(): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_GROUPS });

		const url = new URL(ConfigDMSApi.GroupsEndpoint, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);
		dispatch(removeItem('signs', SignsLoaderState.groupFetch));

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_GROUPS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const groups = (await apiRequestReturn.response.json()) as GroupDto[];
				const normalized = normalize<GroupDto, Record<number, GroupDto>, number>(groups, [
					groupSchema,
				]);
				dispatch({
					type: DMSActionType.SET_GROUPS,
					groupIds: normalized.result,
					entities: normalized.entities,
				});
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_GROUPS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_GROUPS_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const saveGroup =
	(
		name: string,
		signIds: number[],
		id?: number | null,
	): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch(addItem('signs', SignsLoaderState.groupSave));

		const groupReq: CreateUpdateGroupDto = { name, signIds };

		const url = new URL(
			id ? ConfigDMSApi.UpdateGroupEndpoint(id) : ConfigDMSApi.SaveGroupEndpoint,
			APIConfig.endpointURLBase,
		);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: id ? HTTPMethod.PUT : HTTPMethod.POST,
				headers: new Headers({ ...getAPIHeaders() }),
				body: JSON.stringify(groupReq),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);
		const group: GroupDto = {
			id: id ?? undefined,
			name: groupReq.name,
			signs: groupReq.signIds?.map((sId) => ({ id: sId })),
		};

		if (apiRequestReturn.response?.ok && !id) {
			const groupRsp = (await apiRequestReturn.response.json()) as GroupDto;
			group.id = groupRsp.id;
		}
		const normalized = normalize<GroupDto, Record<number, GroupDto>, number>(group, groupSchema);
		dispatch({
			type: DMSActionType.SAVE_GROUP,
			groupId: normalized.result,
			entities: normalized.entities,
		});

		dispatch(removeItem('signs', SignsLoaderState.groupSave));

		return apiRequestReturn;
	};

export const deleteGroups =
	(groupIds: number[]): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch(addItem('signs', SignsLoaderState.groupDelete));

		const url = new URL(ConfigDMSApi.DeleteGroupEndpoint(groupIds), APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.DELETE,
				headers: new Headers({ ...getAPIHeaders() }),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (apiRequestReturn.response?.ok) {
			dispatch({ type: DMSActionType.DELETE_GROUPS, groupIds });
		}

		dispatch(removeItem('signs', SignsLoaderState.groupDelete));

		return apiRequestReturn;
	};

export const addCustomMessageToGroup =
	(
		customMessageId: number,
		start: number,
		end: number,
		priority: number,
		groupId: number,
	): ThunkActionRoot<Promise<void>> =>
	async (dispatch, getState): Promise<void> => {
		dispatch({ type: DMSActionType.ADD_CUSTOM_MESSAGE_TO_GROUP });

		const signs = groupsSelector(getState()).find((group) => group.id === groupId)
			?.signs as SignDto[];

		if (!signs || signs?.length === 0) {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{ title: 'Cannot post message to group with no signs' },
					5000,
				),
			);
		} else {
			await dispatch(
				addCustomMessageToSigns(
					customMessageId,
					signs.map((sign) => sign.id),
					start,
					end,
					priority,
				),
			);
		}
	};
