import { css } from 'lit';
/**
 * @param {*} obj object to be tested for presence of property
 * @param {string} prop key to access property being tested for
 * @returns {boolean} representation of whether obj has prop
 */
export default function hasOwnProperty<X extends {}, Y extends PropertyKey>(
	obj: X,
	prop: Y,
): obj is X & Record<Y, unknown> {
	return Object.prototype.hasOwnProperty.call(obj, prop);
}
