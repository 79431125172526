import { css } from 'lit';
import { PermissionGroupType, RoleWithUsersDto } from '../../../typings/api';
import {
	RolesWithUsersEntities,
	UserManagementAction,
	UserManagementActionType,
} from './user-management-actions';

export type UserManagementState = {
	roleIds: number[];
	entities: RolesWithUsersEntities;
	permissionGroups: PermissionGroupType[];
	isApiErrorPage?: boolean;
	collapsableRoles: Map<number, boolean>;
};

export const USER_MANAGEMENT_STATE_INITIAL: UserManagementState = {
	roleIds: [],
	permissionGroups: [],
	entities: { users: {}, roles: {} },
	collapsableRoles: new Map(),
};
export const UserManagementReducer = (
	state: UserManagementState = USER_MANAGEMENT_STATE_INITIAL,
	action: UserManagementAction | undefined = undefined,
): UserManagementState => {
	if (action?.type === UserManagementActionType.API_ERROR_PAGE) {
		return {
			...state,
			isApiErrorPage: true,
		};
	}
	if (action?.type === UserManagementActionType.GET_ROLES_WITH_USERS) {
		const collapsableRoles = new Map(
			action.roleIds.map((roleId) => [roleId, state.collapsableRoles.get(roleId) ?? false]),
		);
		return {
			...state,
			roleIds: action.roleIds.sort(),
			entities: action.entities,
			collapsableRoles,
		};
	}
	if (action?.type === UserManagementActionType.GET_PERMMISSION_TYPES) {
		return {
			...state,
			permissionGroups: action.permissionGroups,
		};
	}
	if (action?.type === UserManagementActionType.UPDATE_ROLE) {
		// add role to collapsableRoles
		const collapsableRoles = new Map(state.collapsableRoles.set(action.roleId, true));
		return {
			...state,
			roleIds: state.roleIds.includes(action.roleId)
				? state.roleIds
				: [...state.roleIds, action.roleId].sort(),
			entities: {
				users: state.entities.users,
				roles: { ...state.entities.roles, ...action.entities.roles },
			},
			collapsableRoles,
		};
	}
	if (action?.type === UserManagementActionType.UPDATE_USER) {
		return {
			...state,
			entities: {
				// Add or remove a user from roles based on the roleIds array on the user object
				roles: Object.fromEntries(
					Object.entries(state.entities.roles).map(([roleId, role]) => {
						const userId = action.user.id as number;
						const userHasRole = action.user.roleIds?.includes(parseInt(roleId, 10));
						let usersOnRole = (role.users as any as number[]) ?? [];
						if (!userHasRole) {
							usersOnRole = usersOnRole.filter((userId) => userId !== action.user.id);
						} else if (!usersOnRole.includes(userId)) {
							usersOnRole = [...usersOnRole, userId];
						}
						return [roleId, { ...role, users: usersOnRole as any as RoleWithUsersDto['users'] }];
					}),
				),
				users: { ...state.entities.users, [action.user.id as number]: action.user },
			},
		};
	}
	if (action?.type === UserManagementActionType.DELETE_ROLE) {
		return {
			...state,
			roleIds: state.roleIds.filter((roleId) => roleId !== action.roleId),
			// leave role in entities beacause nobody likes to clean up
		};
	}
	if (action?.type === UserManagementActionType.DELETE_USER) {
		return {
			...state,
			entities: {
				// remove user from all roles
				roles: Object.fromEntries(
					Object.entries(state.entities.roles).map(([roleId, role]) => [
						roleId,
						{
							...role,
							users: role.users?.filter((userId) => userId !== action.userId) ?? [],
						},
					]),
				),
				users: Object.fromEntries(
					Object.entries(state.entities.users).filter(
						([userId]) => parseInt(userId, 10) !== action.userId,
					),
				),
			},
		};
	}

	if (action?.type === UserManagementActionType.EXPAND_COLLAPSIBLE_ROLES) {
		const collapsableRoles = new Map(state.roleIds.map((roleId) => [roleId, action.isExpanded]));
		return {
			...state,
			collapsableRoles,
		};
	}

	if (action?.type === UserManagementActionType.EXPAND_COLLAPSIBLE_ROLE) {
		const collapsableRoles = new Map(state.collapsableRoles.set(action.roleId, action.isExpanded));
		return {
			...state,
			collapsableRoles,
		};
	}

	return state;
};
