import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import { CustomMessageDto } from '../../../typings/api';
import { NotificationErrorType } from '../../../typings/shared-types';
import { ConfigDMSApi } from '../../config/ConfigDMS';
import ConfigCARSx, { APIConfig } from '../../config/ConfigCARSx';
import { DMSView, SignsLoaderState } from '../../constants';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { addItem, removeItem } from '../redux-loaderAnim';
import { navigate } from '../redux-routing';
import { ThunkActionRoot } from '../redux-store';
import { showMainBanner } from '../redux-ui';
import { DMSActionType, setDMSLoading } from './dms-actions';

export type GetCustomMessages = Action<DMSActionType.GET_CUSTOM_MESSAGES>;

export interface SetCustomMessages extends Action<DMSActionType.SET_CUSTOM_MESSAGES> {
	customMessages: CustomMessageDto[];
}

export interface SaveCustomMessage extends Action<typeof DMSActionType.SAVE_CUSTOM_MESSAGE> {
	message: CustomMessageDto;
}

export interface SetCustomMessage extends Action<DMSActionType.SET_CUSTOM_MESSAGE> {
	customMessage: CustomMessageDto;
}

export interface DeleteCustomMessage extends Action<DMSActionType.DELETE_CUSTOM_MESSAGE> {
	messageIds: Array<number>;
}

export const setCustomMessage = (customMessage: CustomMessageDto): SetCustomMessage => ({
	type: DMSActionType.SET_CUSTOM_MESSAGE,
	customMessage,
});

export const getCustomMessages =
	(customIds?: Array<number>): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_CUSTOM_MESSAGES });
		const url = new URL(ConfigDMSApi.CustomMessagesEndpoint, APIConfig.endpointURLBase);
		if (customIds) {
			url.searchParams.append('custom-ids', customIds.toString());
		}
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		dispatch(removeItem('signs', SignsLoaderState.customFetch));

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			dispatch(navigate(ConfigCARSx.Pages.login.route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const customMessages = (await apiRequestReturn.response.json()) as CustomMessageDto[];

				if (customIds) {
					dispatch({ type: DMSActionType.SET_CUSTOM_MESSAGE, customMessage: customMessages[0] });
				} else {
					dispatch({ type: DMSActionType.SET_CUSTOM_MESSAGES, customMessages });
				}
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_SIGNS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const saveCustomMessage =
	(message: CustomMessageDto): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: DMSActionType.SAVE_CUSTOM_MESSAGE });
		dispatch(setDMSLoading(true));

		const url = new URL(ConfigDMSApi.CustomMessagesPostEndpoint, APIConfig.endpointURLBase);

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(message),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (apiRequestReturn.response?.ok) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{ title: 'Custom message saved successfully!' },
					5000,
				),
			);
			dispatch(navigate(`${ConfigCARSx.Pages.dms.route}/${DMSView['custom-messages']}`));
		} else {
			dispatch(
				showMainBanner(
					NotificationErrorType.ERROR,
					{
						title: `Error saving custom message`,
						messages: apiRequestReturn.apiError ? [apiRequestReturn.apiError] : undefined,
					},
					5000,
				),
			);
		}
		dispatch(addItem('signs', SignsLoaderState.customFetch));
		await dispatch(getCustomMessages());
		dispatch(setDMSLoading(false));

		return apiRequestReturn;
	};

export const deleteCustomMessage =
	(messageIds: Array<number>): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: DMSActionType.DELETE_CUSTOM_MESSAGE });

		const url = new URL(
			ConfigDMSApi.deleteCustomMessagesByIds(messageIds),
			APIConfig.endpointURLBase,
		);

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.DELETE,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (apiRequestReturn.response?.status === 200) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{
						title: 'Custom message deleted successfully',
					},
					5000,
				),
			);
		}

		dispatch(addItem('signs', SignsLoaderState.customFetch));
		await dispatch(getCustomMessages());

		return apiRequestReturn;
	};
