import { css } from 'lit';
export const ConfigDashboard = {
	// Default to Jan 1 of last year
	fromDate: new Date().setFullYear(new Date().getFullYear() - 1, 0, 1),
	toDate: Date.now(),
};

export const GrafanaConfig = {
	SERVER_URL: 'https://dev.irisx.org/grafana',
	DASHBOARD_UID: 'ef8747ed-a01f-46ca-8173-0a5a66a99c6f',
	ORG_ID: 1,
	performHealthCheck: false,
	enabled: true,
};

export const DASHBOARD_URL = `
	${GrafanaConfig.SERVER_URL}/d/
	${GrafanaConfig.DASHBOARD_UID}
`;
