import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import { GraphicDto } from '../../../typings/api';
import { NotificationErrorType } from '../../../typings/shared-types';
import { ConfigDMSApi } from '../../config/ConfigDMS';
import { APIConfig, DebuggingConfig } from '../../config/ConfigCARSx';
import APIRequest, { APIError, APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { addItem, removeItem } from '../redux-loaderAnim';
import { ThunkActionRoot } from '../redux-store';
import { showMainBanner } from '../redux-ui';
import { DMSActionType } from './dms-actions';

export type GetSignGraphics = Action<DMSActionType.GET_SIGN_GRAPHICS>;

export interface SetSignGraphics extends Action<DMSActionType.SET_SIGN_GRAPHICS> {
	signGraphics: GraphicDto[];
}

export const getSignGraphics =
	(): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_SIGN_GRAPHICS });
		const url = new URL(ConfigDMSApi.SignGraphics, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);
		try {
			const signGraphics: GraphicDto[] = (await apiRequestReturn.response?.json()) as GraphicDto[];
			dispatch({ type: DMSActionType.SET_SIGN_GRAPHICS, signGraphics });
		} catch (error) {
			if (DebuggingConfig.showConsoleLogs) {
				console.error(`unable to parse response from dms graphics api:`, error);
			}
			apiRequestReturn.apiError = APIError.ResponseUnparseable;
		}
	};

export const uploadDMSGraphic =
	(graphicDto: GraphicDto): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: DMSActionType.UPLOAD_DMS_GRAPHIC });
		dispatch(addItem('image-uploader', 'upload-dms-graphic'));
		const url = new URL(ConfigDMSApi.signSaveGraphic, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(graphicDto),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (apiRequestReturn.response?.status === 200) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{
						title: `Upload Successful: "${graphicDto.title}" added to library of DMS graphics.`,
					},
					5000,
				),
			);
		}
		await dispatch(getSignGraphics());
		dispatch(removeItem('image-uploader', 'upload-dms-graphic'));
		return apiRequestReturn;
	};

export const deleteDMSGraphic =
	(graphicId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch(addItem('image-uploader', 'delete-dms-graphic'));
		dispatch({ type: DMSActionType.DELETE_DMS_GRAPHIC });
		const apiRequestReturn = await APIRequest(
			new Request(
				new URL(ConfigDMSApi.signDeleteGraphic(graphicId), APIConfig.endpointURLBase).href,
				{
					method: HTTPMethod.DELETE,
					headers: new Headers({
						...getAPIHeaders(),
					}),
				},
			),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (apiRequestReturn.response?.status === 200) {
			dispatch(
				showMainBanner(
					NotificationErrorType.SUCCESS,
					{
						title: 'DMS Graphic Deleted Successfully',
					},
					5000,
				),
			);
		}
		await dispatch(getSignGraphics());
		dispatch(removeItem('image-uploader', 'delete-dms-graphic'));
		return apiRequestReturn;
	};
