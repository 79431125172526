import { css } from 'lit';
import HTTPMethod from 'http-method-enum';
import { Action } from 'redux';
import { APIConfig } from '../../config/ConfigCARSx';
import { ConfigREMApi } from '../../config/ConfigREM';
import APIRequest, { APIRequestReturn, getAPIHeaders } from '../APIRequest';
import { ThunkActionRoot } from '../redux-store';
import { REMActionType } from './rem-actions';
import REMState from './rem-state';

export type GetREMEventTimeline = Action<typeof REMActionType.GET_REM_EVENT_TIMELINE>;

export interface SetREMEventTimeline extends Action<typeof REMActionType.SET_REM_EVENT_TIMELINE> {
	eventTimeline: REMState['eventTimeline'];
}

export interface AddNoteToEventTimeline
	extends Action<typeof REMActionType.ADD_NOTE_TO_EVENT_TIMELINE> {
	eventId: number;
	note: string;
}

export const setREMEventTimeline = (
	eventTimeline: SetREMEventTimeline['eventTimeline'],
): SetREMEventTimeline => ({
	type: REMActionType.SET_REM_EVENT_TIMELINE,
	eventTimeline,
});

export const addNoteToEventTimeline =
	(note: string, eventId: number): ThunkActionRoot<Promise<APIRequestReturn>> =>
	async (dispatch): Promise<APIRequestReturn> => {
		dispatch({ type: REMActionType.ADD_NOTE_TO_EVENT_TIMELINE });

		const newTimelineNote = { notes: note };

		const apiRequestReturn: APIRequestReturn = await APIRequest(
			new Request(new URL(ConfigREMApi.eventTimeline(eventId), APIConfig.endpointURLBase).href, {
				method: HTTPMethod.POST,
				headers: new Headers({
					...getAPIHeaders(),
				}),
				body: JSON.stringify(newTimelineNote),
			}),
		);

		return apiRequestReturn;
	};
