import { css } from 'lit';
import { ConfigHHApi } from '../../config/ConfigHH';
import { APIRequestReturn } from '../../redux/APIRequest';
import { refreshHH } from '../../redux/hh/redux-hh';
import store from '../../redux/redux-store';

let HHPollingEnabled = false;
let HHPollingTimeout: ReturnType<typeof setTimeout> | undefined;
let HHPollingDelayMs: number;
let HHLastAPIRequestReturn: APIRequestReturn;
let HHPollingId: number;

export const pollHH = async (): Promise<void> => {
	const apiRequestReturn = await store.dispatch(refreshHH(HHPollingId));
	HHLastAPIRequestReturn = apiRequestReturn;

	if (HHPollingEnabled) {
		HHPollingTimeout = setTimeout(() => {
			void pollHH();
		}, HHPollingDelayMs);
	}
};

export const stopPollingHH = (autoAbort = true): void => {
	HHPollingEnabled = false;
	if (HHPollingTimeout !== undefined) {
		clearTimeout(HHPollingTimeout);
	}
	HHPollingTimeout = undefined;

	if (autoAbort && HHLastAPIRequestReturn) {
		HHLastAPIRequestReturn.abortController?.abort();
	}
};

export const startPollingHH = async (
	hhId: number,
	pollingDelayMs = ConfigHHApi.pollingRate,
	pollImmediately = true,
): Promise<void> => {
	if (HHPollingEnabled) {
		return;
	}

	HHPollingEnabled = true;
	HHPollingDelayMs = pollingDelayMs;
	HHPollingId = hhId;

	if (pollImmediately) {
		await pollHH();
	} else {
		HHPollingTimeout = setTimeout(() => {
			void pollHH();
		}, HHPollingDelayMs);
	}
};
