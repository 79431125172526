import { css } from 'lit';
/* eslint-disable max-lines */
import { Action } from 'redux';
import HTTPMethod from 'http-method-enum';
import APIRequest, { APIError, getAPIHeaders } from '../APIRequest';
import { LocationDto, RouteDto, SignFieldDto } from '../../../typings/api';
import { ThunkActionRoot } from '../redux-store';
import { ConfigDMSApi } from '../../config/ConfigDMS';
import ConfigCARSx, { APIConfig } from '../../config/ConfigCARSx';
import { addItem } from '../redux-loaderAnim';
import { navigate } from '../redux-routing';
import { AppSection, SignsLoaderState } from '../../constants';
import { LatLon } from '../../../typings/shared-types';
import {
	DeleteSigns,
	GetSignQueue,
	GetSigns,
	PromoteQueueMessage,
	SaveSign,
	SetSelectedMessage,
	SetSignActiveQueue,
	SetSignQueue,
	SetSigns,
	SetSignsFilterTypes,
	getSigns,
} from './dms-actions-sign';
import {
	AddCustomMessageToGroup,
	DeleteGroups,
	SaveGroup,
	SetGroups,
	getGroups,
} from './dms-actions-group';
import { AddCustomMessageToSigns, SetSign, getSign, getSignQueue } from './dms-actions-sign';
import {
	DeleteCustomMessage,
	GetCustomMessages,
	SaveCustomMessage,
	SetCustomMessage,
	SetCustomMessages,
	getCustomMessages,
} from './dms-actions-custom-message';
import { GetSignGraphics, SetSignGraphics, getSignGraphics } from './dms-actions-graphic';

export enum DMSApiError {
	GetSigns = 'GetSigns',
}

//	ACTION TYPES

export enum DMSActionType {
	SET_SIGNS_ERROR_STATE = 'SET_SIGNS_ERROR_STATE',

	SET_SIGN_QUEUE_ERROR_STATE = 'SET_SIGN_QUEUE_ERROR_STATE',

	SET_GROUPS_ERROR_STATE = 'SET_GROUPS_ERROR_STATE',
	SAVE_SIGN_ERROR_STATE = 'SAVE_SIGN_ERROR_STATE',
	SET_LOCATION_ERROR_STATE = 'SET_LOCATION_ERROR_STATE',

	GET_SIGNS = 'GET_SIGNS',
	SET_SIGNS = 'SET_SIGNS',
	DELETE_SIGNS = 'DELETE_SIGNS',

	FILTER_TYPES = 'FILTER_TYPES',

	GET_GROUPS = 'GET_GROUPS',
	SET_GROUPS = 'SET_GROUPS',

	GET_DMS_SIGN_QUEUE = 'GET_DMS_SIGN_QUEUE',
	SET_DMS_SIGN_QUEUE = 'SET_DMS_SIGN_QUEUE',
	SET_DMS_SIGN_QUEUE_SELECTED_MESSAGE = 'SET_DMS_SIGN_QUEUE_SELECTED_MESSAGE',

	GET_SIGN_GRAPHICS = 'GET_SIGN_GRAPHICS',
	SET_SIGN_GRAPHICS = 'SET_SIGN_GRAPHICS',

	DELETE_CUSTOM_MESSAGE = 'DELETE_CUSTOM_MESSAGE',
	SAVE_CUSTOM_MESSAGE = 'SAVE_CUSTOM_MESSAGE',
	SET_CUSTOM_MESSAGE = 'SET_CUSTOM_MESSAGE',
	GET_CUSTOM_MESSAGES = 'GET_CUSTOM_MESSAGES',
	SET_CUSTOM_MESSAGES = 'SET_CUSTOM_MESSAGES',

	ADD_CUSTOM_MESSAGE_TO_GROUP = 'SAVE_CUSTOM_MESSAGE_TO_GROUP',

	ADD_CUSTOM_MESSAGE_TO_SIGNS = 'ADD_CUSTOM_MESSAGE_TO_SIGNS',

	PROMOTE_QUEUE_MESSAGE = 'PROMOTE_QUEUE_MESSAGE',
	SET_SIGN_ACTIVE_QUEUE = 'SET_SIGN_ACTIVE_QUEUE',
	UPLOAD_DMS_GRAPHIC = 'UPLOAD_DMS_GRAPHIC',
	DELETE_DMS_GRAPHIC = 'DELETE_DMS_GRAPHIC',

	GET_ROUTES = 'GET_ROUTES',
	SET_ROUTES = 'SET_ROUTES',

	SAVE_SIGN = 'SAVE_SIGN',
	SAVE_SIGN_TO_GROUPS = 'SAVE_SIGN_TO_GROUPS',

	SAVE_GROUP = 'SAVE_GROUP',
	DELETE_GROUPS = 'DELETE_GROUPS',

	GET_LOCATION_DETAILS = 'GET_LOCATION_DETAILS',
	SET_LOCATION_DETAILS = 'SET_LOCATION_DETAILS',

	GET_ROUTE_EXTENT = 'GET_ROUTE_EXTENT',
	SET_ROUTE_EXTENT = 'SET_ROUTE_EXTENT',

	UPDATE_SIGN_MESSAGE = 'UPDATE_SIGN_MESSAGE',
	DELETE_SIGN_MESSAGE = 'DELETE_SIGN_MESSAGE',

	GET_SIGN_FIELDS = 'GET_SIGN_FIELDS',
	SET_SIGN_FIELDS = 'SET_SIGN_FIELDS',

	SET_DMS_LOADING = 'SET_DMS_LOADING',

	GET_SIGN = 'GET_SIGN',
	SET_SIGN = 'SET_SIGN',

	SET_DMS_POPUP_LOADING = 'SET_DMS_POPUP_LOADING',
}

interface SetErrorState extends Action<typeof DMSActionType.SET_SIGNS_ERROR_STATE> {
	apiError?: APIError;
}

type GetRoutes = Action<typeof DMSActionType.GET_ROUTES>;

interface SetRoutes extends Action<typeof DMSActionType.SET_ROUTES> {
	routes: RouteDto[];
}

/* eslint-disable camelcase */
interface SetLocationDetails extends Action<DMSActionType.SET_LOCATION_DETAILS> {
	locationDetails: LocationDto;
	route_designator: string;
	miles_along_route: number;
	end_miles: number;
}
/* eslint-enable camelcase */
/* eslint-disable camelcase */
interface GetLocationDetails extends Action<DMSActionType.GET_LOCATION_DETAILS> {
	route_designator: string;
	miles_along_route: number;
	end_miles: number;
}
/* eslint-enable camelcase */

interface GetRouteExtent extends Action<DMSActionType.GET_ROUTE_EXTENT> {
	route: string;
	startMileMarker: number;
	endMileMarker: number;
}
interface SetRouteExtent extends Action<DMSActionType.SET_ROUTE_EXTENT> {
	route: string;
	startMileMarker: number;
	endMileMarker: number;
	coords: Array<LatLon>;
}

interface SetSignFields extends Action<DMSActionType.SET_SIGN_FIELDS> {
	signFields: SignFieldDto;
}

interface SetDMSLoading extends Action<DMSActionType.SET_DMS_LOADING> {
	loading?: boolean;
}

interface SetLocationErrorState extends Action<DMSActionType.SET_LOCATION_ERROR_STATE> {
	apiError?: APIError;
}

interface SetDMSPopupLoading extends Action<DMSActionType.SET_DMS_POPUP_LOADING> {
	signPopupLoading?: boolean;
}

interface SetSignQueueErrorState extends Action<DMSActionType.SET_SIGN_QUEUE_ERROR_STATE> {
	apiError?: APIError;
}

interface SetGroupsErrorState extends Action<DMSActionType.SET_GROUPS_ERROR_STATE> {
	apiError?: APIError;
}

export type DMSAction =
	| SetErrorState
	| SetLocationErrorState
	| GetSigns
	| SetSigns
	| GetCustomMessages
	| SetCustomMessages
	| SetSignsFilterTypes
	| GetSignGraphics
	| SetSignGraphics
	| SetSignQueue
	| GetSignQueue
	| SetSignActiveQueue
	| PromoteQueueMessage
	| SaveCustomMessage
	| GetRoutes
	| SetRoutes
	| SaveSign
	| SetCustomMessage
	| SetLocationDetails
	| GetLocationDetails
	| DeleteCustomMessage
	| GetRouteExtent
	| SetRouteExtent
	| DeleteSigns
	| AddCustomMessageToSigns
	| AddCustomMessageToGroup
	| SetSignFields
	| SetDMSLoading
	| SetSign
	| SetDMSPopupLoading
	| SetSelectedMessage
	| SetSignQueueErrorState
	| SetGroupsErrorState
	| SetGroups
	| SaveGroup
	| DeleteGroups;

//	ACTIONS

export const setDMSLoading = (loading?: boolean): SetDMSLoading => ({
	type: DMSActionType.SET_DMS_LOADING,
	loading,
});

export const setDMSPopupLoading = (signPopupLoading?: boolean): SetDMSPopupLoading => ({
	type: DMSActionType.SET_DMS_POPUP_LOADING,
	signPopupLoading,
});

export const setLocationError = (apiError?: APIError): SetLocationErrorState => ({
	type: DMSActionType.SET_LOCATION_ERROR_STATE,
	apiError,
});

export const getRoutes =
	(): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_ROUTES });

		const url = new URL(ConfigDMSApi.RoutesEndpoint, APIConfig.endpointURLBase);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const routes = (await apiRequestReturn.response.json()) as RouteDto[];
				dispatch({ type: DMSActionType.SET_ROUTES, routes });
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_SIGNS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

// using underscores in param names here to match the query params of the endpoint
/* eslint-disable camelcase */
export const getLocationDetails =
	(
		route_designator: string,
		miles_along_route: number,
		end_miles?: number,
	): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({
			type: DMSActionType.GET_LOCATION_DETAILS,
			route_designator,
			miles_along_route,
			end_miles,
		});

		const url = new URL(ConfigDMSApi.locationDetails(route_designator), APIConfig.endpointURLBase);
		url.searchParams.set('miles_along_route', miles_along_route.toString());
		if (end_miles) {
			url.searchParams.set('end_miles', end_miles.toString());
		}

		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_LOCATION_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const locationDetails: LocationDto =
					(await apiRequestReturn.response.json()) as LocationDto;
				dispatch({
					type: DMSActionType.SET_LOCATION_DETAILS,
					locationDetails,
					route_designator,
					miles_along_route,
					end_miles,
				});
				dispatch({
					type: DMSActionType.SET_LOCATION_ERROR_STATE,
					apiError: undefined,
				});
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_LOCATION_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_LOCATION_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

export const getRouteExtent =
	(route: string, startMileMarker: number, endMileMarker: number): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		dispatch({ type: DMSActionType.GET_ROUTE_EXTENT });

		const url = new URL(
			ConfigDMSApi.routeExtent(route, startMileMarker, endMileMarker),
			APIConfig.endpointURLBase,
		);
		const apiRequestReturn = await APIRequest(
			new Request(url.href, {
				method: HTTPMethod.GET,
				headers: new Headers({
					...getAPIHeaders(),
				}),
			}),
			ConfigDMSApi.endpointTimeoutMs,
		);

		if (!apiRequestReturn?.response) {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		} else if (apiRequestReturn.response.status === 401) {
			void dispatch(navigate(ConfigCARSx.Pages[AppSection.LOGIN].route));
		} else if (apiRequestReturn.response.ok === true) {
			try {
				const coords = (await apiRequestReturn.response.json()) as LatLon;
				dispatch({
					type: DMSActionType.SET_ROUTE_EXTENT,
					route,
					startMileMarker,
					endMileMarker,
					coords,
				});
			} catch (error) {
				dispatch({
					type: DMSActionType.SET_SIGNS_ERROR_STATE,
					apiError: APIError.ResponseUnparseable,
				});
			}
		} else {
			dispatch({ type: DMSActionType.SET_SIGNS_ERROR_STATE, apiError: APIError.FetchFailed });
		}
	};

let pollingSignsTimeout: ReturnType<typeof setInterval>;

export const pollSigns =
	(pollImmediately = false): ThunkActionRoot<Promise<void>> =>
	async (dispatch, getState): Promise<void> => {
		const { signsLastPolled, signIds } = getState().dms;

		if (
			!signIds?.length ||
			pollImmediately ||
			!signsLastPolled ||
			Date.now() - signsLastPolled > ConfigDMSApi.signsPollingRate
		) {
			dispatch(addItem('signs', SignsLoaderState.fetch));
			dispatch(addItem('signs', SignsLoaderState.customFetch));
			dispatch(addItem('signs', SignsLoaderState.groupFetch));
			await Promise.all([
				dispatch(getSigns()),
				dispatch(getCustomMessages()),
				dispatch(getGroups()),
				dispatch(getSignGraphics()),
			]);
		}

		clearInterval(pollingSignsTimeout);
		pollingSignsTimeout = setInterval(() => {
			void dispatch(getSigns());
			void dispatch(getGroups());
		}, ConfigDMSApi.signsPollingRate);
	};

export const stopPollSigns = (): ThunkActionRoot<void> => (): void => {
	clearInterval(pollingSignsTimeout);
};

let pollingSignQueueTimeout: ReturnType<typeof setInterval>;

export const pollSignQueue =
	(signId: number, pollImmediately = false): ThunkActionRoot<Promise<void>> =>
	async (dispatch): Promise<void> => {
		if (pollImmediately) {
			await Promise.all([dispatch(getSign(signId)), dispatch(getSignQueue(signId))]);
		}

		clearInterval(pollingSignQueueTimeout);
		pollingSignQueueTimeout = setInterval(() => {
			void Promise.all([dispatch(getSign(signId)), dispatch(getSignQueue(signId))]);
		}, ConfigDMSApi.signQueuePollingRate);
	};

export const stopPollingSignQueue = (): ThunkActionRoot<void> => (): void => {
	clearInterval(pollingSignQueueTimeout);
};
