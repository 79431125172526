import { css } from 'lit';
import { denormalize } from 'normalizr';
import { ComboBoxOption } from 'cra-web-components/components/combo-box/combo-box';
import { createSelector } from 'reselect';
import {
	AreaOfInfluenceDto,
	CardinalDir,
	CustomMessageDto,
	DMSSignType,
	GraphicDto,
	GroupDto,
	ManufacturerDto,
	MessageDto,
	RouteDto,
	SignDto,
} from '../../../typings/api';
import { locationInfoToKey } from '../../config/ConfigDMS';
import { DmsSignFilterTypes } from '../../constants';
import { groupSchema, signSchema } from '../../schemas';
import { RootState } from '../redux-store';
import { getObjectKeyByValue, isValidNumber, isValidString } from '../../utils/utils';
import { MinMaxTuple } from '../../../typings/shared-types';

export const groupsSelector = createSelector(
	[(state: RootState) => state.dms.groupIds, (state: RootState) => state.dms.entities],
	(groupIds, entities) => {
		return denormalize(groupIds, [groupSchema], entities) as GroupDto[];
	},
);

export const signsSelector = createSelector(
	[(state: RootState) => state.dms.signIds, (state: RootState) => state.dms.entities],
	(signIds, entities) => {
		return denormalize(signIds, [signSchema], entities) as SignDto[];
	},
);

export const selectGroupsToSignDTOs = createSelector(
	[signsSelector, groupsSelector],
	(signs, groups) => {
		const newSigns = [...signs];
		if (!groups) {
			return newSigns;
		}
		// TODO find a cleaner/faster way get signs from groups, and add those groups to signs in state
		// ...it might be that a triple for loop is necessary but hopefully not
		groups.forEach((group) => {
			// eslint-disable-next-line no-unused-expressions
			group.signs?.forEach((signInGroup) => {
				newSigns.forEach((sign) => {
					if (sign.groups) {
						if (signInGroup.id === sign.id) {
							if (!sign.groups.some((g) => g.id === group.id)) {
								sign.groups.push(group);
							}
						}
					} else {
						sign.groups = [];
						if (signInGroup.id === sign.id) {
							sign.groups.push(group);
						}
					}
				});
			});
		});

		return newSigns;
	},
);

export const signsFiltered = createSelector(
	(state: RootState) => state.dms.signIds,
	(state: RootState) => state.dms.entities,
	(_state: RootState, filters: string[]) => filters,
	(_state: RootState, _fitlers: string[], filterExcludeFromRem: boolean) => filterExcludeFromRem,
	(
		_state: RootState,
		_fitlers: string[],
		_filterExcludeFromRem: boolean,
		filterInactivePDMS: boolean,
	) => filterInactivePDMS,
	(signIds, entities, filters, filterExcludeFromRem, filterInactivePDMS) => {
		const signs = denormalize(signIds, [signSchema], entities) as SignDto[];
		if (!filters?.length) {
			return signs;
		}
		const dmsKey = getObjectKeyByValue(DmsSignFilterTypes, DmsSignFilterTypes.DMS) ?? '';
		const filterFullMatrix = filters.includes(
			getObjectKeyByValue(DmsSignFilterTypes, DmsSignFilterTypes.DMSF) ?? '',
		);
		const filterLineMatrix = filters.includes(dmsKey);
		const filtersNoDms = filters.filter((filter) => filter !== dmsKey);
		return signs.filter((sign) => {
			if (filterExcludeFromRem && sign.excludeFromRem) {
				return false;
			}
			if (filterInactivePDMS && sign.type === DMSSignType.PDMS && sign.status !== 'ACTIVE') {
				return false;
			}
			if (filterFullMatrix && sign.type === DMSSignType.DMS && sign.fullMatrix) {
				return true;
			}
			if (filterLineMatrix && sign.type === DMSSignType.DMS && !sign.fullMatrix) {
				return true;
			}

			return filtersNoDms.includes(
				getObjectKeyByValue(DmsSignFilterTypes, DmsSignFilterTypes[sign.type]) ?? '',
			);
		});
	},
);

const routesSelector = (state: RootState): RouteDto[] => state.dms.routes ?? [];

export const selectRouteNames = createSelector(routesSelector, (routes) => {
	return routes.map((route) => {
		return { value: route.routeIdentifier };
	}) as ComboBoxOption[] | undefined;
});

export const selectSignRouteMileRanges = (
	state: RootState,
	route: string,
): MinMaxTuple[] | undefined =>
	state.dms.routes
		?.find((routeDTO) => routeDTO.routeIdentifier === route)
		?.mileRanges?.map((mileRange) => [mileRange[0], mileRange[1]]);

export const selectRouteByName = (state: RootState, routeName: string): RouteDto | undefined =>
	state.dms.routes?.find((route) => route.routeIdentifier === routeName);

export const selectCustomMessageById = (
	state: RootState,
	messageID: number,
): CustomMessageDto | undefined =>
	state.dms.customMessages?.find((message) => (message.id as number) === messageID);

export const selectLaneDirectionsByLocation = (
	state: RootState,
	route?: string,
	startMileMarker?: number,
	endMileMarker?: number,
): Array<CardinalDir> => {
	if (!isValidString(route) || !isValidNumber(startMileMarker)) {
		return [];
	}

	const locationsDict = state.dms.locationDetailsDict;

	if (locationsDict) {
		const locationDetails = locationsDict[locationInfoToKey(route, startMileMarker, endMileMarker)];

		if (locationDetails && isValidNumber(startMileMarker)) {
			if (
				locationDetails.lanes?.positive?.cardinalDir &&
				locationDetails.lanes?.negative?.cardinalDir
			) {
				return [
					locationDetails.lanes.positive.cardinalDir as CardinalDir,
					locationDetails.lanes.negative.cardinalDir as CardinalDir,
				];
			}
		}
	}

	return [];
};

export const getGroup = (state: RootState, groupId: number): GroupDto | undefined =>
	denormalize(groupId, groupSchema, state.dms.entities) as GroupDto | undefined;

export const selectIsSignNumberUnique = (state: RootState, num: number): boolean => {
	return Object.values(state.dms.entities.signs).find((sign) => sign.number === num) === undefined;
};

export const selectGraphicById = (state: RootState, graphicId: number): GraphicDto | undefined =>
	state.dms.signGraphics?.find((graphic) => (graphic.id as number) === graphicId);

export const selectRouteExtentByLocation = (
	state: RootState,
	route?: string,
	start?: number,
	end?: number,
): Array<[number, number]> | undefined => {
	const { routeExtentDict } = state.dms;

	if (routeExtentDict && route && isValidNumber(start) && isValidNumber(end)) {
		const extent = routeExtentDict[locationInfoToKey(route, start, end)];

		return extent
			? extent.map((value: { lat: number; lon: number }) => [value.lat, value.lon])
			: undefined;
	}

	return undefined;
};

export const selectSignById = (
	state: RootState,
	signId: number | undefined | null,
): SignDto | undefined => (isValidNumber(signId) ? state.dms.entities.signs[signId] : undefined);

export const selectGroupsBySign = (state: RootState, signId: number): Set<GroupDto> =>
	new Set(
		groupsSelector(state).filter((group) => group.signs?.some((sign) => sign?.id === signId)),
	);

export const selectGraphicsByMessage = (
	state: RootState,
	message: MessageDto,
): (GraphicDto | undefined)[] =>
	[message.frame1, message.frame2, message.frame3].map((frame) =>
		frame?.graphicId ? selectGraphicById(state, frame?.graphicId) : undefined,
	);

export const selectRouteExtentsByAois = createSelector(
	(state: RootState) => state,
	(_state: RootState, aois: AreaOfInfluenceDto[]) => aois,
	(state, aois) => {
		return aois.map((aoi) => selectRouteExtentByLocation(state, aoi.route, aoi.startMp, aoi.endMp));
	},
);
export const selectSignManufacturersToComboboxOptions = (state: RootState): ComboBoxOption[] =>
	state.dms.signFields?.manufacturers?.map((manufacturer) => {
		return { value: manufacturer.name ?? '' };
	}) ?? [];

export const selectManufacturerByName = (
	state: RootState,
	name: string,
): ManufacturerDto | undefined =>
	state.dms.signFields?.manufacturers?.find((manufacturer) => manufacturer.name === name);
