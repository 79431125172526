import { css } from 'lit';
import { MILLISECONDS_PER_SECOND } from '../constants';

const ConfigLogin = {
	Logo: {
		icon: 'logo.png',
		alt: 'Indiana Department of Transportation',
		href: '/', //  clicking the link goes here
	},
};

export const ConfigLoginApi = {
	loginUser: (): string => `auth/signin`,
	getUserPermissions: (): string => `auth`,
};

export const ConfigUserManagementApi = {
	getRolesWithUsers: (): string => `users/roles`,
	getPermissionTyes: (): string => `users/permissions`,
	createUser: (): string => `users`,
	createRole: (): string => `users/roles`,
	changeUserPassword: (userId: number): string => `users/${userId}/change-password`,
	editUser: (userId: number): string => `users/${userId}`,
	editRole: (roleId: number): string => `users/roles/${roleId}`,
	deleteUser: (userId: number): string => `users/${userId}`,
	deleteRole: (roleId: number): string => `users/roles/${roleId}`,
	/* password reset */
	sendPasswordResetEmail: (email: string): string => `auth/password-reset/${email}`,
	checkPasswordResetToken: (token: string): string => `auth/check-reset-password-token/${token}`,
	submitPasswordReset: (token: string): string => `auth/reset-password/${token}`,
	rolePollingRate: 60 * MILLISECONDS_PER_SECOND,
};

interface PasswordRequirement {
	validate: (value: string) => boolean;
	errorMessage: string;
	description: string;
}

export const ConfigUserManagement = {
	showManageUsersPage: false,
};

const EASILY_GUESSABLE_PASSWORD_PARTS = [
	'pass',
	'word',
	'123',
	'qwerty',
	'secret',
	'1111',
	'abc123',
	'iloveyou',
];

export const PASSWORD_REQUIREMENTS: PasswordRequirement[] = [
	{
		validate: (val: string) => val.length > 8,
		errorMessage: 'Password must be more than 8 characters',
		description: 'Be at least 8 characters long',
	},
	{
		validate: (val: string) => /[A-Z]/.test(val),
		errorMessage: 'Password must contain at least one uppercase letter',
		description: 'Contain at least one uppercase letter',
	},
	{
		validate: (val: string) => /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/.test(val),
		errorMessage: 'Password must contain a symbol',
		description: 'Contain a symbol',
	},
	{
		validate: (val: string) => /[0-9]/.test(val),
		errorMessage: 'Password must contain a number',
		description: 'Include a number',
	},
	{
		validate: () => true,
		errorMessage: '',
		description: 'Not contain your username or email',
	},
	{
		validate: (val: string) => {
			for (const part of EASILY_GUESSABLE_PASSWORD_PARTS) {
				if (val.toLowerCase().includes(part)) {
					return false;
				}
			}
			return true;
		},
		errorMessage: 'Must not contain an easily guessable password part, e.g. "pass", "123"',
		description: 'Not contain an easily guessable password part, e.g. "pass", "123"',
	},
];

export default ConfigLogin;
