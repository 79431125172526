import { css } from 'lit';
import { TemplateResult } from 'lit';
import { DirectiveResult } from 'lit/directive';
import { UnsafeHTMLDirective } from 'lit/directives/unsafe-html.js';

export type DeepRequired<T> = {
	[K in keyof T]: Required<DeepRequired<T[K]>>;
};

export type LatLngBounds = {
	NorthLat: number;
	EastLon: number;
	SouthLat: number;
	WestLon: number;
};

export type LatLng = {
	lat: number;
	lng: number;
};

export type LatLon = {
	lat: number;
	lon: number;
};

export enum NotificationErrorType {
	ERROR = 'ERROR',
	SUCCESS = 'SUCCESS',
	WARNING = 'WARNING',
}

export enum BannerLocation {
	MAIN = 'MAIN',
	MODAL = 'MODAL',
}

export type Banner = {
	id: number;
	type: NotificationErrorType;
	messaging: { title: string; messages: string[] };
	timeout: number;
	location: BannerLocation;
};

export type TimelineDayEntryChange = {
	subject: string;
	description: string;
	highlighted: boolean;
};

export type TimelineDayEntry = {
	timeString: string; //	HH:MM ZZZ
	user: string;
	changes: TimelineDayEntryChange[];
};

export type TimelineDay = {
	dateString: string; //	"MM/DD/YYYY"
	entries: TimelineDayEntry[];
};

export type GeoSearchResult = {
	label: string;
	lat: number;
	lng: number;
};

export enum MonitorGroup {
	MY_MONITORS = 'MY_MONITORS',
	ALL_MONITORS = 'ALL_MONITORS',
}

export type APIErrorJSON = {
	timestamp: string;
	status: number;
	error: string;
	message: string;
	path: string;
};

export type MinMaxTuple = [number, number];

export type CartesianCoordinates = {
	x: number;
	y: number;
};

export type RenderResult =
	| TemplateResult
	| DirectiveResult<typeof UnsafeHTMLDirective>
	| string
	| number
	| undefined;

export enum StartEnd {
	START = 'start',
	END = 'end',
}

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

export type Color = RGB | RGBA | HEX;
