import { css } from 'lit';
import { DraftEvent, LaneBlockage, LaneImpactValue, RouteClassification } from '../../typings/api';
import { isLaneImpacted } from './utils';
import REMState from '../redux/rem/rem-state';

export const isRampAffected = (laneImpact?: LaneBlockage | null) =>
	laneImpact?.entranceRampAffected || laneImpact?.exitRampAffected;

export const determinePriorityForLaneImpact = (
	laneImpact: LaneBlockage,
	laneCount: number,
	routeClassification: RouteClassification,
): number => {
	const percentAffected = laneImpact.lanesAffected
		? laneImpact.lanesAffected.length / laneCount
		: 0;

	if (percentAffected === 1) {
		if (routeClassification === RouteClassification.INTERSTATE) {
			return 1;
		}
		return 2;
	}
	if (percentAffected >= 0.01 && percentAffected <= 0.99) {
		if (
			routeClassification === RouteClassification.STATE ||
			routeClassification === RouteClassification.US
		) {
			return 3;
		}
		if (percentAffected >= 0.51 && percentAffected <= 0.99) {
			return 2;
		}
		return 3;
	}
	if (laneImpact.insideShoulderAffected || laneImpact.outsideShoulderAffected) {
		return 4;
	}

	if (isRampAffected(laneImpact)) {
		if (routeClassification === RouteClassification.INTERSTATE) {
			return 1;
		}
		if (
			routeClassification === RouteClassification.STATE ||
			routeClassification === RouteClassification.US
		) {
			return 2;
		}
	}

	return 5;
};

export const determineEventPriority = (state: REMState, event: DraftEvent): number => {
	if (
		(!isLaneImpacted(event.positiveLaneBlockage) ||
			event.positiveLaneBlockageType === LaneImpactValue.NA) &&
		(!isLaneImpacted(event.negativeLaneBlockage) ||
			event.negativeLaneBlockageType === LaneImpactValue.NA)
	) {
		return (
			state.eventFields?.eventTypes?.find((eventType) => eventType.name === event.eventType)
				?.defaultPriority?.priorityLevel ?? 5
		);
	}

	const priorityFallback =
		state.eventFields?.eventTypes?.find((eventType) => eventType.name === event.eventType)
			?.defaultPriority?.priorityLevel ?? 5;

	let positivePriority =
		event.positiveLaneBlockage && event.positiveLaneBlockageType === LaneImpactValue.CLOSED
			? determinePriorityForLaneImpact(
					event.positiveLaneBlockage,
					event.locationDetails?.positiveLanes?.laneCount ?? NaN,
					event.locationDetails?.classification as RouteClassification,
			  )
			: priorityFallback;
	if (
		isRampAffected(event.positiveLaneBlockage) &&
		event.positiveLaneBlockageType === LaneImpactValue.SLOW
	) {
		positivePriority = 3;
	}

	let negativePriority =
		event.negativeLaneBlockage && event.negativeLaneBlockageType === LaneImpactValue.CLOSED
			? determinePriorityForLaneImpact(
					event.negativeLaneBlockage,
					event.locationDetails?.negativeLanes?.laneCount ?? NaN,
					event.locationDetails?.classification as RouteClassification,
			  )
			: priorityFallback;
	if (
		isRampAffected(event.negativeLaneBlockage) &&
		event.negativeLaneBlockageType === LaneImpactValue.SLOW
	) {
		negativePriority = 3;
	}

	return positivePriority < negativePriority ? positivePriority : negativePriority;
};
