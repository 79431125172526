import { css } from 'lit';
import { customElement } from 'lit/decorators.js';
import { CSSResultArray, LitElement, TemplateResult, css, html } from 'lit';
import { connect } from 'pwa-helpers/connect-mixin';
import store from '../../redux/redux-store';
import spinnerCss from '../css/spinner.css';

@customElement('loading-page')
export default class LoadingPage extends connect(store)(LitElement) {
	public static override get styles(): CSSResultArray {
		return [
			spinnerCss,
			css`
				.loading-wrapper {
					display: flex;
				}
				.loading-text {
					color: rgb(var(--brand-grey-dark));
					text-align: center;
					margin-top: calc(var(--ggutter) * 2);
				}
			`,
		];
	}

	public override render(): TemplateResult {
		return html`<div id="loading-wrapper">
			<p class="loading-text font-lg">Your page is taking a while to load, please wait</p>
		</div> `;
	}
}
